const state = {
    offset: null,
    scrolled: false,
    sidebar: false
};

const mutations = {
    async setFixedOffset(state, offset) {
        state.offset = offset;
    },
    async setScroll(state, scrolled) {
        state.scrolled = scrolled;
    },
    async setSidebar(state, sidebar) {
        state.sidebar = sidebar;
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
