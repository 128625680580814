<template>
  <a v-if="is_external"
     target="_blank"
     class="body__card"
     v-bind:href="href">
    <div class="card-container">
      <template v-if="image">
        <div class="card-thumbnail-wrapper">
          <img class="card-thumbnail"
               v-bind:src="`${uploadsPath}${image.url}`"/>
        </div>
      </template>

      <div class="card__content">
        <div class="card__title">
          {{title}}
        </div>
        <richtext-block v-if="description"
                        v-bind:source="description"
                        v-bind:read_more_limit="240"
                        class="card__description"/>
      </div>
    </div>
  </a>
  <router-link v-else
               v-bind:to="href"
               class="body__card">
    <div class="card-container">
      <template v-if="image">
        <div class="card-thumbnail-wrapper">
          <img class="card-thumbnail"
               v-bind:src="`${uploadsPath}${image.url}`"/>
        </div>
      </template>

      <div class="card__content">
        <div class="card__title">
          {{title}}
        </div>
        <richtext-block v-if="description"
                        v-bind:source="description"
                        v-bind:read_more_limit="240"
                        class="card__description"/>
      </div>
    </div>
  </router-link>
</template>

<script>
import richtextBlock from '@/components/utils/richtext-block';

export default {
    components: {richtextBlock},
    props: {
        image: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        href: {
            type: String,
            default: null
        },
        is_external: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {uploadsPath: process.env.VUE_APP_API_CMS};
    }
};
</script>

<style lang="scss" scoped>
.body__card {
  @apply flex my-3 rounded border-l-4 border-primary-800 text-accent-800 shadow bg-white hover:bg-core-100 cursor-pointer;

  .card-container{
    @apply flex min-h-[7rem] justify-center items-center p-5 sm:px-8 space-x-5 sm:space-x-6;

    .card-thumbnail-wrapper {
      @apply flex flex-grow w-full min-w-[4rem] max-w-[4rem] h-[4rem]
      sm:min-w-[5.5rem] sm:max-w-[5.5rem] sm:h-[5.5rem] rounded-full shadow sm:shadow-md ring-4 ring-accent-200 overflow-hidden;

      .card-thumbnail {
        @apply flex bg-core-300 object-cover;
      }
    }

    .card__content {
      @apply w-full flex flex-wrap flex-shrink;

      .card__title{
          @apply w-full text-sm sm:text-lg font-bold leading-tight sm:leading-snug transition;
      }
      &:hover .card__title{
        @apply text-primary-700;
      }

      .card__description {
        @apply text-xs sm:text-sm mt-2 leading-tight;
      }
    }
  }
}
</style>
