<template>
  <div class="footer">

    <div class="footer__info">
      <div class="info__content--top">

        <div class="content__services">
          <div class="services__wrapper">
            <div class="services__title">CNCC Services</div>
            <div class="address__text">
              <div class="text__address">
                <font-awesome-icon class="address__icon"
                                   icon="map-marker-alt"
                                   size="lg"/>
                <div class="address__full">
                  <p class="text__service">Département formation</p>
                  <p class="text__street">200&nbsp;-&nbsp;216 Rue Raymond Losserand</p>
                  <p class="text__city">75014 PARIS</p>
                </div>
              </div>
            </div>
            <div class="contact__text">
              <a href="mailto:cnccservices.formation@cncc.fr" class="text__email">
                <font-awesome-icon class="email__icon"
                                   icon="envelope"
                                   size="lg"/>
                <span class="email__url">cnccservices.formation@cncc.fr</span>
              </a>
              <p class="text__telephone">
                <font-awesome-icon class="telephone__icon"
                                   icon="phone-square-alt"
                                   flip="horizontal"
                                   size="lg"/>
                <span class="telephone__number">01 44 77 82 99</span>
                <!-- <span class="telephone__number--legend">(de 09h00 à 18h00 du lundi au vendredi)</span> -->
              </p>
            </div>
            <div class="inscription__button">
              <a href="https://www.cncc.fr/users/self#communications"
                 target="_blank"
                 class="inscription__link">
                <strong>Inscrivez-vous à la Newsletter Formation</strong>
              </a>
            </div>
            <div class="contact__button">
              <router-link to="/contacts"
                           class="contact__link">
                <strong>Contactez-nous</strong>
              </router-link>
            </div>
            <div class="socialmedia__buttons">
              <div class="socialmedia__wrapper">
                <social-media class="socialmedia__list"/>
              </div>
            </div>
          </div>
        </div>

        <div class="content__contact">
          <div class="contact__wrapper">
            <div class="contact__title">Informations légales</div>

            <div class="contact__text">
              <router-link tag="a"
                           class="text__link"
                           to="/conditions">
                Conditions générales et modalités d’inscription
              </router-link>
              <router-link tag="a"
                           class="text__link"
                           to="/reglement">
                Règlement intérieur applicable aux participants
              </router-link>
              <router-link tag="a"
                           class="text__link"
                           to="/mentions">
                Mentions légales
              </router-link>
              <a tag="a"
                 class="text__link"
                 v-bind:href="qualiopiLink">
                Certificat Qualiopi
              </a>
            </div>

            <div class="contact__logo">
              <div class="contact__logo__container">
                <div class="logo__wrapper logo__wrapper--cncc">
                  <img src="@/assets/img/logo_white.png"/>
                </div>
                <div class="logo__wrapper logo__wrapper--certif">
                  <img src="https://cdn.cncc.fr/download/logoqualiopi-150dpi-avecmarianne.png"/>
                </div>
              </div>
              <div class="text__wrapper">
                <p class="text__title">La certification qualité a été délivrée au titre des catégories d'actions suivantes :</p>
                <p class="text__subtitle">ACTIONS DE FORMATION</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__legal">
      <div class="legal__wrapper">

        <div class="legal__item legal__item--copyright">
          ©&nbsp;CNCC Formation {{new Date().getFullYear()}} Tous droits réservés
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import socialMedia from '@/components/utils/social-media.vue';

export default {
    components: {socialMedia},
    computed: {
        qualiopiLink() {
            return `https://${process.env.VUE_APP_HOST_CATALOGUE}/qualiopi`;
        }
    }
};
</script>

<style lang="scss" scoped>
.footer {

    .footer__info {
        @apply w-full bg-primary-900;

        .info__content--top {
            @apply container mx-auto flex flex-wrap sm:flex-nowrap items-stretch py-5 text-white text-xs uppercase;

            .content__services {
                @apply w-full px-4;

                .services__wrapper {
                    @apply w-full p-3;

                    .services__title {
                        @apply font-bold text-sm mb-2;
                    }

                    .address__text {
                        @apply pl-3 border-l-2;

                        .text__address{
                          @apply inline-block;
                          .address__full {
                            @apply ml-3 inline-block align-top;
                          }
                        }

                        .text__company {
                            @apply inline-flex font-bold mb-2;
                        }
                    }
                    .contact__text {
                        @apply pt-2 pl-3 border-l-2;

                            .text__email {
                                @apply text-sm lowercase inline-block mb-2;
                                .email__icon {}
                                .email__url {
                                    @apply ml-3 underline inline-block align-middle;
                                }
                            }

                            .text__telephone {
                                @apply text-sm font-bold mb-1;
                                .telephone__icon {
                                  @apply align-middle;
                                }

                                .telephone__number {
                                    @apply ml-3 inline-block align-middle;
                                }
                                .telephone__number--legend {
                                    @apply flex w-full font-light text-xs italic lowercase;
                                }
                            }
                    }
                    .inscription__button {
                      @apply mt-6;

                        .inscription__link {
                            @apply inline-flex bg-accent-700 hover:bg-accent-800 transition px-4 py-2;
                        }
                    }
                    .contact__button {
                      @apply mt-4;

                        .contact__link {
                            @apply inline-flex bg-secondary-600 hover:bg-secondary-700 transition px-4 py-2;
                        }
                    }
                    .socialmedia__buttons {
                        @apply -ml-1 pt-3;
                    }
                }
            }

            .content__contact {
                @apply w-full px-4;

                .contact__wrapper {
                    @apply w-full p-3;
                }

                .contact__title {
                    @apply font-bold text-sm mb-2;
                }

                .contact__text {
                    @apply pl-3 border-l-2;

                    .text__link {
                        @apply text-sm block hover:underline mb-2;
                        text-transform: initial;
                        &:last-child{
                          @apply mb-0;
                        }
                    }
                }

                .contact__logo {
                  @apply flex flex-wrap;

                  .contact__logo__container {
                    @apply w-2/3 flex items-end space-x-8 my-8;

                    .logo__wrapper {

                      &.logo__wrapper--cncc {
                        @apply w-3/5;
                      }

                      &.logo__wrapper--certif {
                        @apply w-2/5;
                      }

                    }
                  }
                  .text__wrapper {
                    @apply text-sm;
                    .text__title {}
                    .text__subtitle {
                      @apply font-bold;
                    }
                  }
                }
              }
        }
    }

    .footer__legal {
        @apply w-full bg-primary-900;

        .legal__wrapper {
            @apply container mx-auto flex items-center justify-around h-20 text-xs font-bold uppercase;

            .legal__item.legal__item--copyright {
                @apply text-core-100;
            }
        }
    }

}
</style>
