<template>
  <div class="simplebar-scroll">
    <transition name="fade">
      <font-awesome-icon v-if="scrolled"
                         v-on:click="scrollTop"
                         class="simplebar-scroll__icon"
                         v-bind:class="[
                           is_dark && current_of.themed_scrollbar ?
                             'simplebar-scroll__icon--inverted-color'
                             : 'simplebar-scroll__icon--default-color'
                         ]"
                         fixed-width
                         icon="chevron-up"/>
    </transition>
  </div>
</template>

<script>
import {tailwindsColors} from '@/assets/data/tailwind-config-resolver';
import {of_list} from '@/assets/data/data_of_logo.js';

import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

export default {
    props: {
        target: {type: String},
        is_dark: {type: Boolean}
    },
    data() {
        return {
            scrolled: false,
            is_parent_hovered: false,
            current_target: null,
            simplebar: null,
            scroll_spy: null
        };
    },
    computed: {
        current_of() {
            return of_list[process.env.VUE_APP_OF_KEY];
        }
    },
    watch: {
        is_parent_hovered(newValue) {
            if (newValue) {
                this.current_target.classList.add('simplebar-active-container');
            } else if (!this.scrolled) {
                this.current_target.classList.remove('simplebar-active-container');
            }
        },
        scrolled(newValue) {
            if (!newValue) {
                setTimeout(() => {
                    if (!this.is_parent_hovered) {
                        this.current_target.classList.remove('simplebar-active-container');
                    }
                }, 300);
            }
        }
    },
    mounted() {
        const r = document.querySelector(':root');

        r.style.setProperty('--current-scrollbar_color', !this.current_of.themed_scrollbar ? tailwindsColors['core'][600] : this.is_dark ? tailwindsColors['white'] : tailwindsColors['primary'][900]);

        this.current_target = this.$parent.$refs[this.target];

        this.simplebar = new SimpleBar(this.current_target, {autoHide: false});

        this.current_target.addEventListener('mouseover', e => {
            this.is_parent_hovered = true;
        });

        this.current_target.addEventListener('mouseout', e => {
            this.is_parent_hovered = false;
        });
        this.scroll_spy = this.simplebar.getScrollElement();

        if (this.scroll_spy) {
            this.scroll_spy.addEventListener('scroll', this.onScroll);
        }
    },
    beforeDestroy() {
        this.current_target.removeEventListener('mouseover', e => {
            this.is_parent_hovered = true;
        });

        this.current_target.removeEventListener('mouseout', e => {
            this.is_parent_hovered = false;
        });
        if (this.scroll_spy) {
            this.scroll_spy.removeEventListener('scroll', this.onScroll);
        }
        this.simplebar.unMount();
        SimpleBar.removeObserver();
    },
    methods: {
        onScroll(event) {
            this.scrolled = event.target.scrollTop > 10;
        },
        scrollTop() {
            const scroller = this.simplebar.getScrollElement();

            scroller.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
</script>
  <style lang="scss">
  :root {
      --current-scrollbar_color: theme('colors.primary.900');
  }
  .simplebar-scrollbar.simplebar-visible::before {
      background-color: var(--current-scrollbar_color) !important;
      @apply transition ease-out duration-100 opacity-30;
  }
  .simplebar-active-container {
      .simplebar-scrollbar::before,
      .simplebar-scrollbar.simplebar-visible::before {
          @apply opacity-75;
      }
  }

  .simplebar-scroll--inverted-color::before {
     @apply bg-white transition ease-out duration-100 opacity-30 #{!important};
  }
  </style>
  <style lang="scss" scoped>
  .simplebar-scroll {
      @apply z-10 absolute right-4 bottom-4;

      .simplebar-scroll__icon {
          background-color: var(--current-scrollbar_color) !important;
          @apply cursor-pointer w-6 h-6 p-4 rounded-full opacity-25 transition ease-in-out duration-200 text-white shadow-md;
          &:hover {
              @apply opacity-75;
          }

          &.simplebar-scroll__icon--inverted-color {
              @apply border border-primary-900/25 text-primary-900 opacity-80 #{!important};
              &:hover {
                  @apply opacity-100 #{!important};
              }
          }
      }
  }
  .svg-inline--fa {
      @apply w-full;
  }
  </style>
