import Cookies from 'js-cookie';

import {AUTH} from '@/http-common';

const STORED_ROUTE_PATH = sessionStorage.getItem('redirect_route_path') ? sessionStorage.getItem('redirect_route_path') : null;

const state = {
    user: false,
    authenticated: false,
    redirect_route_path: STORED_ROUTE_PATH,
    access_token: null,
    id_token: null
};

const mutations = {
    async setUser(state, userinfo) {
        state.user = userinfo;
    },
    setAuthenticationStatus(state) {
        if (!state.user) {
            state.authenticated = false;
        } else {
            state.authenticated = true;
        }

        setTimeout(()=> {
            const getDomain = () => {
                const hostname = process.env.VUE_APP_HOST_API
                    .split('.')
                    .reverse();

                return `${hostname[1]}.${hostname[0]}`;
            };

            Cookies.set(
                'auth_status', state.authenticated, {
                    domain: `.${getDomain()}`,
                    sameSite: 'Strict'
                }
            );
        }, 100);
    },
    setRedirectUrlPath(state, route_path) {
        if (route_path) {
            state.redirect_route_path = route_path;
            sessionStorage.setItem('redirect_route_path', state.redirect_route_path);
        }
    },
    storeAuthTokens(state, tokens) {
        state.id_token = tokens.id_token;
        state.access_token = tokens.access_token;
    }
};

const actions = {
    async checAuth({commit}) {
        const fetched_user_info = await AUTH.get('/userinfo');

        await commit('setUser', fetched_user_info && fetched_user_info.data);
    },
    async applyAuthenticationStatus({
        dispatch, commit
    }) {
        await dispatch('checAuth');
        await commit('setAuthenticationStatus');
    },
    async setUser({
        dispatch, commit, state
    }) {
        await dispatch('applyAuthenticationStatus');
    }
};

// Define a getter in order to get your current username from your state
const getters = {
    username: (state) => {
        return state.user && state.user.name;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
