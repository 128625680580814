<template>
  <div class="social-media">

    <template v-for="(item, index_item) in social_media_list">

      <text-button v-if="item.icon"
                   v-bind:key="`item${index_item}`"
                   v-bind:href="item.href"
                   v-bind:icon="item.icon"
                   v-bind:size="item.size"
                   class="hover:text-accent-300"
                   external_link="true"/>

      <a v-else
         target="_blank"
         class="cursor-pointer flex p-1 hover:opacity-80"
         v-bind:href="item.href"
         v-bind:key="`item${index_item}`">
        <img v-bind:src="item.src" class="w-28 object-contain"/>
      </a>
    </template>

  </div>
</template>

<script>
import textButton from '@/components/utils/text-button.vue';

export default {
    components: {textButton},
    data() {
        return {
            social_media_list: [
                {
                    icon: ['fab', 'facebook'],
                    href: 'https://www.facebook.com/CNCCFormation/',
                    size: '2x'
                },
                {
                    icon: ['fab', 'twitter'],
                    href: 'https://twitter.com/cnccformation',
                    size: '2x'
                },
                {
                    icon: ['fab', 'linkedin'],
                    href: 'https://fr.linkedin.com/in/cncc-formation-8846a214a',
                    size: '2x'
                },
                {
                    src: 'https://cdn.cncc.fr/download/icon-audit-cie.png',
                    href: 'https://auditcie.cncc.fr/',
                    size: '2x'
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.social-media {
    @apply inline-flex items-center m-2.5 text-base;
}
</style>
