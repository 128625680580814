<template>
  <div id="app">
    <header-layout ref="header"/>

    <div ref="layout_wrapper"
         class="layout-wrapper">

      <main class="main-content"
            v-bind:class="[
              {'lg:container mx-auto sm:px-4': !$route.meta.no_container_wrapper},
              {'bg-primary-100 lg:rounded-md lg:shadow-lg py-6 lg:py-14 px-4 lg:px-16': is_default_page}
            ]">
        <transition name="fade-router" mode="out-in">
          <router-view v-bind:key="$route.name"/>
        </transition>
      </main>

      <footer-layout/>
    </div>

    <simplebar-scroll target="layout_wrapper"
                      ref="simplebar_ref"
                      v-bind:is_dark="$is_cncc || $is_irf_ceeca"
                      v-on:on_scroll="(e) => setScroll(e)"/>

    <app-check-update/>

  </div>
</template>

<script>
import headerLayout from '@/components/header/header-layout.vue';
import footerLayout from '@/components/footer/footer-layout.vue';

import appCheckUpdate from '@/components/utils/app-check-update.vue';
import simplebarScroll from '@/components/utils/simplebar-scroll.vue';

import {createNamespacedHelpers} from 'vuex';
const {
    mapMutations: mapMutationsObserver, mapState: mapStateObserver
} = createNamespacedHelpers('observer');

export default {
    components: {
        appCheckUpdate,
        headerLayout,
        footerLayout,
        simplebarScroll
    },
    computed: {
        ...mapStateObserver({scrolled: (state) => state.scrolled}),
        is_default_page() {
            return ![
                'actualite',
                'preview',
                'conditions',
                'accueil',
                'reglement'
            ].includes(this.$route.name);
        }
    },
    watch: {
        $route() {
            setTimeout(() => {
                this.$refs['simplebar_ref'].scrollTop();
            }, 300);
        },
        authenticated(newVal, oldVal) {
            if (newVal === false && this.$route.meta.requiresAuth) {
                window.location.reload();
            }
        }
    },
    methods: {...mapMutationsObserver({setScroll: 'setScroll'})}
};
</script>

<style lang="scss">
#app,
body,
html {
    @apply h-screen bg-primary-900 text-core-600 font-sans antialiased overflow-hidden;
    font-size: 14px;
}

#app {
    @apply h-full flex flex-col;
}

.layout-wrapper {
    @apply h-full flex flex-col flex-grow overflow-y-auto overflow-x-hidden;
}

.main-content {
    @apply flex-1 w-full mx-auto lg:my-16;
}

.simplebar-scrollbar:before {
    @apply opacity-25 transition duration-300 ease-out;
}
</style>
