import Accueil from '@/views/index.vue';

import ErrorCms from '@/views/errors/error-cms.vue';
import ErrorServer from '@/views/errors/error-server.vue';
import ErrorAuthentification from '@/views/errors/error-authentification.vue';
import ErrorNotFound from '@/views/errors/error-404.vue';

import AuthViewRedirector from '@/views/redirector.vue';

const navigation_tree = [
    {
        path: '/',
        name: 'accueil',
        meta: {
            label: 'Accueil',
            icon: 'home'
        },
        component: Accueil
    },
    {
        path: '/qui-sommes-nous',
        name: 'qui-sommes-nous',
        meta: {
            label: 'Qui sommes-nous ?',
            icon: 'address-card'
        },
        component: () => import('@/views/qui-sommes-nous.vue')
    },
    {
        path: '/dans-vos-locaux',
        name: 'dans-vos-locaux',
        meta: {
            label: 'Dans vos locaux',
            icon: 'users'
        },
        component: () => import('@/views/dans-vos-locaux.vue')
    },
    {
        path: '/ressources',
        name: 'ressources',
        meta: {
            label: 'Ressources',
            icon: 'folder-open'
        },
        component: () => import('@/views/ressources.vue')
    },
    {
        path: '/actualite/:actualiteId',
        name: 'actualite',
        component: () => import('@/views/actualite.vue')
    },
    {
        path: '/preview/:previewId',
        name: 'preview',
        component: () => import('@/views/preview.vue')
    },
    {
        path: '/contacts',
        name: 'contacts',
        meta: {
            label: 'Contacts',
            icon: 'phone-alt'
        },
        component: () => import('@/views/contacts.vue')
    },
    {
        path: '/conditions',
        name: 'conditions',
        meta: {label: 'Conditions générales et modalités d\'inscription en ligne'},
        component: () => import('@/views/conditions.vue')
    },
    {
        path: '/reglement',
        name: 'reglement',
        meta: {label: 'Règlement intérieur applicable aux participants'},
        component: () => import('@/views/reglement.vue')
    },
    {
        path: '/mentions',
        name: 'mentions',
        meta: {label: 'Mentions légales'},
        component: () => import('@/views/mentions.vue')
    },
    {
        path: '*',
        name: 'error-404',
        component: ErrorNotFound
    },
    {
        path: '/error-cms',
        name: 'error-cms',
        props: true,
        component: ErrorCms
    },
    {
        path: '/error-server',
        name: 'error-server',
        props: true,
        component: ErrorServer
    },
    {
        path: '/error-authentification',
        name: 'error-authentification',
        props: true,
        component: ErrorAuthentification
    },
    {
        path: '/redirector',
        name: 'redirector',
        meta: {no_container_wrapper: true},
        component: AuthViewRedirector
    }
];

export default {navigation_tree};
