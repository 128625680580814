<template>
  <div>
    <modal-box v-if="show_update_msg"
               width_class="max-w-screen-md w-full"
               v-on:close="reportNextUpdate()">

      <template #modal-header>
        <h1 class="text-xl text-core-700">
          Mise à jour de l'application
        </h1>
      </template>

      <template #modal-body>
        <div class="p-6 text-xl">
          <p class="mb-4">Une version plus récente de cette application est disponible.</p>
          <p class="font-bold">Souhaitez-vous mettre à jour ?</p>
        </div>
      </template>

      <template #modal-footer>
        <div class="w-full inline-flex space-x-4 justify-end">
          <div class="cursor-pointer w-64 bg-primary-900 py-3 px-5 hover:bg-primary-800 text-white font-bold rounded shadow text-center"
               v-on:click="refreshToUpdateApp()">
            Mettre à jour (Recommandé)
          </div>
          <div v-on:click="reportNextUpdate()"
               class="cursor-pointer w-64 bg-core-300 py-3 px-5 hover:bg-core-200 text-core-700 font-bold rounded shadow text-center">
            Annuler
          </div>
        </div>
      </template>

    </modal-box>
  </div>
</template>

<script>
import {checkUpdate} from '@/vue_app_check_update.js';
import modalBox from '@/components/utils/modal-box.vue';
export default {
    components: {modalBox},
    data() {
        return {show_update_msg: false};
    },
    created() {
        this.checkIsAppUpdated();
    },
    methods: {
        reportNextUpdate() {
            const delay = 1 * 60 * 1000; // 1min

            this.checkIsAppUpdated(delay);
            this.show_update_msg = false;
        },
        refreshToUpdateApp() {
            return location.reload(true);
        },
        checkIsAppUpdated(delay) {
            const initial_delay = delay;

            checkUpdate(
                process.env.VUE_APP_VERSION,
                () => this.show_update_msg = true,
                initial_delay
            );
        }
    }
};
</script>

<style lang="scss" scoped>

</style>