import { render, staticRenderFns } from "./simplebar-scroll.vue?vue&type=template&id=2015e46e&scoped=true"
import script from "./simplebar-scroll.vue?vue&type=script&lang=js"
export * from "./simplebar-scroll.vue?vue&type=script&lang=js"
import style0 from "./simplebar-scroll.vue?vue&type=style&index=0&id=2015e46e&prod&lang=scss"
import style1 from "./simplebar-scroll.vue?vue&type=style&index=1&id=2015e46e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2015e46e",
  null
  
)

export default component.exports