/**
 * Returns formatted number to fr locale
 *
 * The reason of this method is that our node version is only built with en-US
 * support. The other option is to provide icu data to node
 * https://nodejs.org/api/intl.html#intl_providing_icu_data_at_runtime
 */
function toFrNumber(value) {

    // issue #271
    if (!value) {
        return '';
    }

    const str_value = value.toString();
    const splits = str_value.split('.');
    const integer_part = splits[0];
    const decimal_part = splits[1];

    // regexp from aglae.number.js
    let reponse = integer_part.replace(/-?\d{1,3}(?=(\d{3})+(?!\d))/g, '$&' + ' ');

    if (decimal_part) {
        reponse += ',' + decimal_part;
    }

    return reponse;
}

function padNumber(value, size) {

    if (!size) {
        size = 2;
    }

    let response = value ? value.toString() : '';

    while (response.length < size) {
        response = '0' + response;
    }

    return response;
}

function toFrDate(date_str) {
    if (!date_str) {
        return '';
    }
    const date = new Date(date_str);

    return `${padNumber(date.getDate())}/${padNumber(date.getMonth() + 1)}/${date.getFullYear()}`;
}

function toFrDateTime(date_str) {
    if (!date_str) {
        return '';
    }
    const date = new Date(date_str);

    return `${padNumber(date.getDate())}/${padNumber(date.getMonth() + 1)}/${date.getFullYear()} ${padNumber(date.getHours())}:${padNumber(date.getMinutes())}`;
}

function dateToUid(in_date) {
    if (!in_date) {
        return '';
    }
    const date = new Date(in_date);

    return `${date.getFullYear()}${padNumber(date.getMonth() + 1)}${padNumber(date.getDate())}-${padNumber(date.getHours())}${padNumber(date.getMinutes())}${padNumber(date.getSeconds())}`;
}

module.exports = {
    toFrNumber,
    padNumber,
    toFrDate,
    toFrDateTime,
    dateToUid
};
