<template>
  <router-link v-if="href && !external_link"
               v-bind:to="href"
               exact-path
               class="text-link">
    <div v-if="icon"
         class="text-link__icon"
         v-bind:class="{'bg-white text-core-700': dark}"
         v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon v-bind:icon="icon"
                         v-bind:flip="icon_flip"
                         v-bind:size="size"/>
    </div>

    <span v-if="label"
          class="text-link__name"
          v-bind:class="{'text-link__name--responsive': responsive}"
          v-bind:style="text_margin">{{label}}</span>

  </router-link>

  <a v-else-if="href && external_link"
     v-bind:target="`${external_same_tab ? '' : '_blank'}`"
     v-bind:href="href"
     class="text-link">

    <div class="text-link__icon"
         v-bind:class="{'bg-white text-core-700': dark}"
         v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon v-bind:icon="icon"
                         v-bind:flip="icon_flip"
                         v-bind:size="size"/>
    </div>

    <span v-if="label"
          class="text-link__name"
          v-bind:class="{'text-link__name--responsive': responsive}"
          v-bind:style="text_margin">{{label}}</span>

  </a>

  <div v-else class="text-link">

    <div class="text-link__icon"
         v-bind:class="{'bg-white text-core-700': dark}"
         v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon v-bind:icon="icon"
                         v-bind:flip="icon_flip"
                         v-bind:size="size"/>
    </div>

    <span v-if="label"
          class="text-link__name"
          v-bind:class="{'text-link__name--responsive': responsive}"
          v-bind:style="text_margin">
      <span class="truncate">{{label}}</span>
      <span v-if="description"
            class="w-full font-normal mt-1 text-core-500 truncate">
        {{description}}
      </span>
    </span>
  </div>
</template>

<script>
export default {
    props: [
        'href',
        'icon',
        'icon_flip',
        'size',
        'label',
        'label_margin',
        'width',
        'height',
        'dark',
        'description',
        'external_link',
        'external_same_tab',
        'responsive'
    ],
    computed: {
        text_margin() {
            return `margin-left: ${this.label_margin / 4}rem`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.text-link {
    @apply w-full h-full flex flex-wrap;
    @include text-link();

    .text-link__icon {
        @include round-icon();
    }

    .text-link__name {
        @apply flex flex-wrap truncate;

        &.text-link__name--responsive {
          @apply hidden;
          @media (min-width: theme('screens.lg')) {
            @apply block;
          }
        }
    }
}
</style>
