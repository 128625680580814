<template>
  <div class="slider-container" ref="carousel_color">
    <!-- <pre>{{slides}}</pre> -->
    <!-- Vue Easy Slider Container -->
    <slider animation="fade" v-model="sliderIndex">
      <slider-item v-for="(slide, index) in slides"
                   v-bind:key="index"
                   v-bind:style="slide">
        <a target="_blank"
           v-bind:href="slide.url ? slide.url : null"
           class="w-full h-full">
          <img class="slider-image" v-bind:src="`${uploadsPath}${slide.image.url}`"/>
          <div v-if="slide.titre || slide.description" class="slider-text">
            <p class="text-lg font-bold">{{slide.titre}}</p>
            <p>{{slide.description}}</p>
          </div>
        </a>
      </slider-item>
    </slider>

    <!-- Extended Thumbnail's Navigation Container -->
    <div v-if="thumbnails" class="slider-thumbnails">
      <div class="slider-thumbnail-wrapper"
           v-bind:class="{'slider-thumbnail-wrapper--active': sliderIndex === index}"
           v-for="(slide, index) in slides"
           v-bind:key="index">
        <div v-on:click="moveToIndex(index)"
             class="slider-thumbnail"
             v-bind:style="`background-image: url('${uploadsPath}${slide.image.url}')`"></div>
      </div>
    </div>

    <hr v-if="separator" class="text-core-300 mt-6 mb-4 max-w-6xl mx-auto"/>
  </div>
</template>

<script>
import {Slider,
    SliderItem} from 'vue-easy-slider';

export default {
    components: {
        Slider,
        SliderItem
    },
    props: {
        slides: Array,
        thumbnails: Boolean,
        separator: Boolean,
        background_color: String,
        color: String
    },
    data() {
        return {
            sliderIndex: 0,
            uploadsPath: process.env.VUE_APP_API_CMS
        };
    },
    watch: {
        color() {
            this.setColor();
        },
        background_color() {
            this.setColor();
        }
    },
    mounted() {
        this.setColor();
    },
    methods: {
        moveToIndex(index) {
            this.sliderIndex = index;
        },
        setColor() {
            if (this.color || this.background_color || !this.color && !this.background_color) {
                const element = this.$refs.carousel_color;

                element.style.setProperty('--background-color', this.background_color);
                element.style.setProperty('--text-color', this.color);
            }
        }
    }
};
</script>

<style lang="scss">
:root {
    --background-color: transparent;
    --text-color: currentColor;
}

.slider-container {
    .slider {
        @apply z-0;

        .slider-item{
          @apply relative;

          .slider-text{
            @apply absolute bottom-0 left-0 right-0 text-center text-white p-4;
            background-color: rgba(0,0,0,.5);
          }
        }
        .slider-indicators {
            top: 0.25rem;
            .slider-indicator-icon {
                border: 1px solid var(--text-color);
            }
            .slider-indicator-active {}
        }
        .slider-btn {
            transition: all 0.5s ease;
            &.slider-btn-left {
                background: linear-gradient(to right, rgba(185, 185, 185, 0.1), 25%, rgba(0,0,0,0));
                &:hover {
                    background: linear-gradient(to right, rgba(75, 75, 75, 0.1), 50%, rgba(0,0,0,0));
                }
            }
            &.slider-btn-right {
                background: linear-gradient(to left, rgba(125, 125, 125, 0.1), 25%, rgba(0,0,0,0));
                &:hover {
                    background: linear-gradient(to left, rgba(75, 75, 75, 0.1), 50%, rgba(0,0,0,0));
                }
            }
            .slider-icon {
                @apply border-l-4 border-b-4;
                border-color: var(--text-color);
            }
        }
        .slider-btn:hover .slider-icon {
            @apply border-l-4 border-b-4 border-core-700;
        }

        .slider-image {
            background-color: var(--background-color);
            @apply object-contain w-full h-full;
        }
    }

    .slider-thumbnails {
        @apply flex flex-wrap w-full mt-4 justify-center;

        .slider-thumbnail-wrapper {
            @apply cursor-pointer flex w-16 h-16 mx-1 rounded-full overflow-hidden;
            max-width: 10rem;
            background-color: var(--background-color);
            transition: all 0.2s ease;
            transform: scale(0.9);
            filter: grayscale(100%);

            .slider-thumbnail {
              @apply flex flex-1 bg-cover bg-no-repeat bg-center;
            }

            &:hover, &.slider-thumbnail-wrapper--active {
                transform: scale(1.1);
                filter: none;
                @apply shadow;
                .slider-thumbnail {
                  filter: none;
                }
            }
        }
    }
}
</style>
