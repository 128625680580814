import axios from 'axios';
import {addMilliseconds, format} from 'date-fns';
import frLocale from 'date-fns/locale/fr';

const CHECK_UPDATES_INITIAL_DELAY = 3 * 1000; // 3s
const CHECK_UPDATES_SUBSEQUENT_DELAY = 1 * 3600 * 1000; // 1h

function delay(ms) {
    return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

function fetchVersion() {
    // Make sure the version file is never retrieved from cache
    return axios.get('/spa-version.json?_=' + new Date()
        .getTime())
        .then((res) => {
            return res.data.version;
        });
}

function checkUpdateLoop(clientside_version, action) {
    return fetchVersion()
        .then((serverside_version) => {
            // eslint-disable-next-line no-console
            console.log('Version du serveur:\n', serverside_version);

            // eslint-disable-next-line no-console
            console.log('Version du client:\n', clientside_version);

            if (clientside_version != serverside_version) {
                // eslint-disable-next-line no-console
                console.log('Mise à jour requise');

                action();
            } else {
                const date = addMilliseconds(new Date(), CHECK_UPDATES_SUBSEQUENT_DELAY);

                // eslint-disable-next-line no-console
                console.log('Mise à jour non-requise, prochaine vérification: \n' + format(
                    date, 'EEE dd MMM yyyy HH:mm', {locale: frLocale}
                ));

                return delay(CHECK_UPDATES_SUBSEQUENT_DELAY)
                    .then(() => {
                        return checkUpdateLoop(clientside_version, action);
                    });
            }
        });
}

function checkUpdate(
    clientside_version, action, initial_delay
) {
    if (initial_delay !== undefined) {
        const date = addMilliseconds(new Date(), initial_delay);

        // eslint-disable-next-line no-console
        console.log('Prochain contrôle de mise à jour: \n' + format(
            date, 'EEE dd MMM yyyy HH:mm', {locale: frLocale}
        ));
    }

    initial_delay = initial_delay !== undefined ? initial_delay
        : CHECK_UPDATES_INITIAL_DELAY;

    return delay(initial_delay)
        .then(() => {
            return checkUpdateLoop(clientside_version, action);
        });
}

export {checkUpdate};