<template>
  <div>
    <div v-if="authenticated" class="h-full">
      <div v-click-outside="closeOpener"
           v-on:click="toggleOpener"
           v-bind:class="displayCustomStyles"
           class="w-full h-full flex cursor-pointer px-2 lg:px-4 transition duration-200 ease">
        <span class="inline-flex items-center">
          <text-button v-bind:label="username"
                       size="lg"
                       icon="user"
                       width="2rem"
                       height="2rem"
                       label_margin="5"
                       v-bind:responsive="true"
                       v-bind:dark="false"
                       class="lg:mr-3 max-w-[15rem] 2xl:max-w-[20rem]"
                       v-bind:class="{'active': opener}"/>

        </span>
        <span class="inline-flex pointer-events-none inset-y-0 right-0 items-center justify-end">
          <svg class="fill-current h-4 w-4"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </span>
      </div>

      <transition name="slide-up">
        <div v-if="opener" class="max-w-xs absolute right-0 mt-1 rounded-lg shadow-lg lg:shadow text-core-600 bg-core-100 mr-3 z-50 font-normal overflow-hidden">
          <text-button v-bind:label="username"
                       size="lg"
                       icon="user"
                       width="2rem"
                       height="2rem"
                       label_margin="5"
                       v-bind:dark="true"
                       class="bg-accent-700 text-core-100 py-4 pl-4 pr-8"/>

          <text-button v-bind:href="accountLink"
                       v-on:click="opener = false"
                       v-bind:label="'Accèder à Mes Espaces'"
                       size="lg"
                       icon="house-user"
                       width="2rem"
                       height="2rem"
                       v-bind:dark="false"
                       label_margin="5"
                       class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-4 pl-4 pr-8 transition duration-150 ease"
                       v-bind:external_link="true"/>

          <text-button v-bind:href="aideEnLigneLink"
                       v-on:click="opener = false"
                       v-bind:label="'Aide en ligne'"
                       size="lg"
                       icon="circle-question"
                       width="2rem"
                       height="2rem"
                       v-bind:dark="false"
                       label_margin="5"
                       class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-4 pl-4 pr-8 transition duration-150 ease"
                       v-bind:external_link="true"/>

          <div v-on:click="triggerCGVActions()">
            <text-button v-bind:href="'/conditions'"
                         v-bind:label="'Consulter les CGV'"
                         size="lg"
                         icon="landmark"
                         width="2rem"
                         height="2rem"
                         v-bind:dark="false"
                         label_margin="5"
                         class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-4 pl-4 pr-8 transition duration-150 ease"/>
          </div>
          <div v-on:click="closeOpener()">
            <text-button v-bind:href="qualiopiLink"
                         v-bind:external_link="true"
                         v-bind:label="'Certificat Qualiopi'"
                         size="lg"
                         icon="forward"
                         width="2rem"
                         height="2rem"
                         v-bind:dark="false"
                         label_margin="5"
                         class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-4 pl-4 pr-8 transition duration-150 ease"/>
          </div>

          <logout-button classes="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-4 pl-4 pr-8 transition duration-150 ease"/>

          <div class="w-full text-center px-2 text-accent-400 normal-case my-1">
            <code>version:&nbsp;{{version}}</code>
          </div>
        </div>
      </transition>

    </div>

    <div v-else
         class="h-full"
         v-click-outside="closeOpener">
      <div v-on:click="toggleOpener"
           v-bind:class="displayCustomStyles"
           class="w-full h-full flex cursor-pointer px-2 lg:px-4 transition duration-800 ease">
        <span class="w-full">
          <text-button label="Se connecter"
                       size="lg"
                       icon="user"
                       width="2rem"
                       height="2rem"
                       class="lg:mr-3"
                       v-bind:responsive="true"
                       v-bind:dark="false"
                       label_margin="5"
                       v-bind:class="{'active': opener}"/>
        </span>
        <span class="flex-1 pointer-events-none inset-y-0 right-0 flex items-center justify-end">
          <svg class="fill-current h-4 w-4"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </span>
      </div>
      <transition name="slide-up">
        <div v-if="opener"
             class="max-w-xs absolute right-0 mr-3 mt-1 rounded-lg text-center shadow-lg lg:shadow text-core-600 bg-core-100 z-50 py-2 font-normal">
          <login-form v-on:logged-in="closeOpener" is_navbar/>

          <text-button v-bind:href="accountLink"
                       v-on:click="opener = false"
                       v-bind:label="'Accèder à Mes Espaces'"
                       size="lg"
                       icon="house-user"
                       width="2rem"
                       height="2rem"
                       v-bind:dark="false"
                       label_margin="5"
                       class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-2 px-8 transition duration-150 ease mt-4"
                       v-bind:external_link="true"/>

          <text-button v-bind:href="aideEnLigneLink"
                       v-on:click="opener = false"
                       v-bind:label="'Aide en ligne'"
                       size="lg"
                       icon="circle-question"
                       width="2rem"
                       height="2rem"
                       v-bind:dark="false"
                       label_margin="5"
                       class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-2 px-8 transition duration-150 ease"
                       v-bind:external_link="true"/>

          <div v-on:click="triggerCGVActions()">
            <text-button v-bind:href="'/conditions'"
                         v-bind:label="'Consulter les CGV'"
                         size="lg"
                         icon="landmark"
                         width="2rem"
                         height="2rem"
                         v-bind:dark="false"
                         label_margin="5"
                         class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-2 px-8 transition duration-150 ease"/>
          </div>
          <div v-on:click="closeOpener()">
            <text-button v-bind:href="qualiopiLink"
                         v-bind:external_link="true"
                         v-bind:label="'Certificat Qualiopi'"
                         size="lg"
                         icon="forward"
                         width="2rem"
                         height="2rem"
                         v-bind:dark="false"
                         label_margin="5"
                         class="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-2 px-8 transition duration-150 ease"/>
          </div>
          <div class="w-full text-center px-2 text-accent-400 normal-case my-1">
            <code>version:&nbsp;{{version}}</code>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

import textButton from '@/components/utils/text-button.vue';
import loginForm from '@/components/utils/login-form.vue';
import logoutButton from '@/components/utils/logout-button.vue';

import {createNamespacedHelpers} from 'vuex';

const {
    mapState: mapStateAuth,
    mapGetters: mapGettersAuth,
    mapMutations: mapMutationsAuth
} = createNamespacedHelpers('auth');

export default {
    components: {
        loginForm,
        logoutButton,
        textButton
    },
    directives: {clickOutside: vClickOutside.directive},
    data() {
        return {
            opener: false,
            redirect_path: null
        };
    },
    computed: {
        ...mapStateAuth({
            user: (state) => state.user,
            authenticated: (state) => state.authenticated
        }),
        ...mapGettersAuth(['username']),
        accountLink() {
            return `https://${process.env.VUE_APP_HOST_ESPACE}/account/informations`;
        },
        aideEnLigneLink() {
            return `https://${process.env.VUE_APP_HOST_ESPACE}/aide-en-ligne`;
        },
        qualiopiLink() {
            return `https://${process.env.VUE_APP_HOST_CATALOGUE}/qualiopi`;
        },
        version() {
            return process.env.VUE_APP_VERSION;
        },
        displayCustomStyles() {
            if (this.$is_cncc) {
                const opener_cncc = this.opener ? 'bg-primary-700' : '';

                return `hover:text-core-300 ${opener_cncc}`;
            } else if (this.$is_cfpc) {
                const opener_cfpc = this.opener ? '' : '';

                return `${opener_cfpc}`;
            } else if (this.$is_irf_ceeca) {
                const opener_ceeca = this.opener ? 'bg-secondary-700' : '';

                return `hover:text-primary-900 ${opener_ceeca}`;
            } else {
                const opener = this.opener ? 'bg-accent-100 text-core-700' : '';

                return `hover:bg-accent-100 hover:text-core-900 ${opener}`;
            }
        }
    },
    created() {
        this.redirect_path = this.$route.fullPath;
    },
    beforeUpdate() {
        this.redirect_path = this.$route.fullPath;
    },
    methods: {
        ...mapMutationsAuth({setRedirectUrlPath: 'setRedirectUrlPath'}),
        closeOpener() {
            this.opener = false;
        },
        toggleOpener() {
            this.opener = !this.opener;
        },
        triggerCGVActions() {
            this.setRedirectUrlPath(this.redirect_path);
            this.closeOpener();
        }
    }
};
</script>

<style lang="scss" scoped>

// https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
// Must use prefix :deep() for SASS ( instead of prefix >>> ) to make vue-loader properly styling the children component within a scoped state.
:deep(.text-link) {
    @apply justify-start;

    &.text-link--conditions {
      @apply justify-center hover:text-secondary-700 active:shadow-inner py-4 px-6 transition;

      .text-link__name {
        @apply flex-none;
      }
    }

    .fill-current,
    .text-link__name {
        @apply flex-1;
        color: inherit;
    }
    &.active.alternative,
    &:hover {
        .text-link__name {
            // @apply text-white;
        }
    }
}
</style>
