<template>
  <div class="error-404"
       v-bind:class="`${width}`">

    <div class="error-404__content">
      <div class="content__title">Erreur 404</div>
      <div class="content__text">
        La page que vous essayez d'atteindre n'existe pas...
      </div>

      <img class="content__img"
           src="@/assets/img/not-found-404.png"
           alt="Image Erreur 404"/>

      <router-link v-if="!no_return_home"
                   class="content__btn-back"
                   v-bind:to="{path: '/'}">
        <font-awesome-icon icon="home" class="btn-back__icon"/>
        <span class="btn-back__text">Retour à la page d'accueil</span>
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
    props: {
        no_return_home: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'max-w-xl'
        }
    }
};
</script>

<style lang="scss" scoped>
.error-404 {
    @apply flex flex-wrap items-center mx-auto h-full overflow-hidden;

    .error-404__content {
      @apply flex flex-wrap justify-center;

      &.error-404__content--is-cncc {
        .content__title, .content__text {
          @apply text-white;
        }
      }

      .content__title {
        @apply text-primary-900 font-bold text-8xl mb-4;
      }

      .content__text {
        @apply text-primary-900 text-lg;
      }

      .content__img {
        @apply max-w-md;
        transform: scaleX(-1);
      }

      .content__btn-back {
        @apply flex items-center justify-center rounded bg-white border border-accent-300 hover:border-accent-400 text-core-700 hover:text-core-900 py-2 px-8 transition;

        .btn-back__icon {
          @apply mr-4;
        }

        .btn-back__text {
          @apply inline-flex align-middle;
        }

      }
    }
}
</style>
