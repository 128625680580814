import Vue from 'vue';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);
const trimHasNumber = val => Number(val.replace('px', ''));

const debounce = function(func, wait) {
    let timeout;

    return () => {
        const later = function() {
            timeout = null;
        };
        const callNow = !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func();
        }
    };
};

const screens = {
    sm: trimHasNumber(fullConfig.theme.screens.sm),
    md: trimHasNumber(fullConfig.theme.screens.md),
    lg: trimHasNumber(fullConfig.theme.screens.lg),
    xl: trimHasNumber(fullConfig.theme.screens.xl)
};

const getBreakpoint = width => {
    if (width >= screens.xl) {
        return 'xl';
    }
    if (width >= screens.lg) {
        return 'lg';
    }
    if (width >= screens.md) {
        return 'md';
    }
    if (width >= screens.sm) {
        return 'sm';
    }

    return 'all';
};

const breakpoints = Vue.observable({
    w: window.innerWidth,
    h: window.innerHeight,
    is: getBreakpoint(window.innerWidth),
    screens
});

window.addEventListener(
    'resize',
    debounce(() => {
        breakpoints.w = window.innerWidth;
        breakpoints.h = window.innerHeight;
        breakpoints.is = getBreakpoint(window.innerWidth);
    }, 200),
    false
);

export default breakpoints;
