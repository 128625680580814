<template>
  <div class="lds-dual">
    <div class="lds-dual-ring" v-bind:class="extra_classes"></div>
  </div>
</template>

<script>
export default {
    props: {
        colors: String,
        sizes: String,
        thickness: String
    },
    computed: {
        extra_classes() {
            const colors = this.colors ? this.colors : null;
            const sizes = this.sizes ? this.sizes : ' w-8 h-8';
            const thickness = this.thickness ? this.thickness : 'border-4';

            return `${colors} ${sizes} ${thickness}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.lds-dual {
    @apply flex w-full;
}
.lds-dual-ring {
    @apply mx-auto my-8 rounded-full;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
