<template>
  <div class="flex justify-center items-center">
    <form method="post"
          class="pt-6 px-6 flex flex-col">

      <div class="flex items-center justify-between text-sm">
        <a v-bind:href="connectWithOidcProvider"
           v-on:click="triggerRedirectPath"
           class="w-full flex h-16 items-center justify-center rounded shadow hover:shadow-md uppercase leading-none bg-jinius-dark-green/80 text-white hover:bg-opacity-90 font-bold py-5 px-8 focus:outline-none transition duration-150 ease-in-out">
          <div class="flex w-5 h-5 mr-3">
            <img src="@/assets/img/favicon.svg"
                 alt="..."
                 class="filter brightness-125"/>
          </div>
          <span>Me&nbsp;connecter&nbsp;à&nbsp;JINIUS</span>
        </a>
      </div>

      <div class="flex items-center justify-center text-sm mt-4">
        <a target="_blank"
           v-bind:href="createNewAccount"
           class="w-full flex h-16 items-center justify-center rounded shadow hover:shadow-md uppercase leading-none bg-white text-accent-700 hover:bg-opacity-90 font-bold py-5 px-8 focus:outline-none transition duration-150 ease-in-out"
           v-tooltip="is_navbar ? {
             placement: 'bottom',
             offset: '10',
             /* show:true, */
             /* autoHide:false, */
             content: safirInfo()
           } : null">
          <span>Créer&nbsp;un&nbsp;compte</span>
          <img src="@/assets/img/jinius.svg"
               alt="Jinius"
               class="inline-flex w-16 py-1 px-2"/>
          <font-awesome-icon v-if="is_navbar"
                             size="1x"
                             v-bind:icon="['fas', 'info-circle']"/>
        </a>
      </div>
    </form>

    <div v-if="!is_navbar"
         v-html="safirInfo()"
         class="flex flex-col text-left ml-2 pl-6 border-core-400 border-l-1">
    </div>

  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {
    mapState: mapStateAuth, mapMutations: mapMutationsAuth
} = createNamespacedHelpers('auth');

export default {
    props: {
        is_navbar: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapStateAuth({authenticated: (state) => state.authenticated}),
        connectWithOidcProvider() {
            return `${process.env.VUE_APP_API_AUTH}/login?to=${process.env.VUE_APP_AUTH_REDIRECT}`;
        },
        createNewAccount() {
            return `${process.env.VUE_APP_API_AUTH}/registration/redirect?to=${process.env.VUE_APP_AUTH_REDIRECT}`;
        }
    },
    methods: {
        ...mapMutationsAuth({setRedirectUrlPath: 'setRedirectUrlPath'}),
        triggerRedirectPath() {
            if (!this.$route.meta.requiresAuth && this.$route.name !== 'connexion') {
                this.setRedirectUrlPath(this.$route.fullPath);
            }
        },
        safirInfo() {
            return `<section class='w-84 py-4 px-2 mb-0'>
          <h6 class='m-0 mb-4'>Jinius Connect qu'est-ce que c'est ?</h6>
          <p class='m-0 text-sm text-justify'>Jinius Connect est le dispositif d'authentification sécurisé de la plateforme Jinius.</p>
          </section>`;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
