const default_options = {
    desktop: 'logo',
    mobile: 'logo',
    breakpoint: 'lg',
    themed_scrollbar: true
};

const of_list = {
    cncc: {
        ...default_options,
        desktop: 'banniere',
        themed_scrollbar: false
    },
    cfpc: {
        ...default_options,
        desktop: 'banniere'
    },
    'cncc-compagnie': {...default_options},
    'crcc-ab': {...default_options},
    'crcc-bd': {...default_options},
    'crcc-bt': {...default_options},
    'crcc-colmar': {...default_options},
    'crcc-ds': {...default_options},
    'crcc-est': {...default_options},
    'crcc-fdf': {...default_options},
    'crcc-ga': {...default_options},
    'crcc-hdf': {...default_options},
    'crcc-lr': {...default_options},
    'crcc-mn': {...default_options},
    'crcc-normandie': {...default_options},
    'crcc-oa': {
        ...default_options,
        desktop: 'banniere'
    },
    'crcc-paris': {...default_options},
    'crcc-sddlr': {...default_options},
    'crcc-toulouse': {...default_options},
    'crcc-vec': {
        ...default_options,
        desktop: 'banniere'
    },
    'cso-stage': {...default_options},
    'irf-afecc': {...default_options},
    'irf-aforce': {...default_options},
    'irf-arfec': {...default_options},
    'irf-asfodem': {...default_options},
    'irf-asforef': {...default_options},
    'irf-auditec': {...default_options},
    'irf-bfc': {...default_options},
    'irf-ceeca': {
        ...default_options,
        desktop: 'banniere'
    },
    'irf-ceeccara': {...default_options},
    'irf-formega': {...default_options},
    'irf-cfpca': {...default_options},
    'irf-cl': {...default_options},
    'irf-ifaura': {...default_options},
    'irf-crefora': {...default_options},
    'irf-f2c': {...default_options},
    'irf-forom': {...default_options},
    'irf-ifrec': {...default_options},
    'irf-ifrecom': {...default_options},
    'irf-ifyc': {...default_options},
    'irf-numeria': {
        ...default_options,
        desktop: 'banniere'
    },
    'irf-pcg': {...default_options}
};

export {of_list};
