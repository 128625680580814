<template>
  <div class="header-cart-button"
       v-bind:class="[
         {'header-cart-button--is-cncc': $is_cncc},
         {'header-cart-button--is-cfpc': $is_cfpc},
         {'header-cart-button--is-irf-ceeca': $is_irf_ceeca},
       ]">
    <a v-bind:href="goToPanier" class="button__link">
      <span v-if="authenticated && countPanier" class="button__count">
        {{countPanier}}
      </span>
      <font-awesome-icon v-bind:icon="icon"
                         v-bind:size="size"
                         class="button__icon"/>
      <span class="button__label">Mon panier</span>
    </a>
  </div>
</template>

<script>
import {API} from '@/http-common';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth} = createNamespacedHelpers('auth');

export default {
    data() {
        return {
            icon: ['fas', 'shopping-cart'],
            size: 'lg',
            countPanier: null
        };
    },
    computed: {
        ...mapStateAuth({authenticated: (state) => state.authenticated}),
        goToPanier() {
            return `https://${process.env.VUE_APP_HOST_INSCRIPTION}/panier`;
        }
    },
    watch: {$route: 'fetchCountPanier'},
    async created() {
        await this.fetchCountPanier();
    },
    methods: {
        async fetchCountPanier() {
            if (this.authenticated) {
                const response = await API.get('/inscription/profile', this.$header_skip_redirection);

                this.countPanier = response.data && response.data.nbPanierFormation;
            }
        }
    }
};
</script>

  <style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .header-cart-button {
      @apply inline-flex justify-around w-20 h-full items-stretch z-50 hover:text-primary-900 px-2 lg:px-6;
      .button__link {
          @apply relative flex items-center w-full h-full;

          .button__icon {
              @apply mx-1;
          }

          .button__count {
              @apply absolute -mt-7 ml-5 flex flex-1 w-5 h-5 text-sm items-center justify-center bg-primary-700 text-white rounded-full;
          }

          .button__label {
              @apply hidden;
              @media (min-width: theme('screens.lg')) {
                  @apply ml-4 block;
              }
          }
      }

      &.header-cart-button--is-cncc {
          @apply hover:text-core-300;
          .button__count {
              @apply bg-secondary-600;
          }
      }

      &.header-cart-button--is-cfpc {
          @apply lg:px-12;
          .button__link {
              @apply lg:justify-end;
              .button__count {
                  @apply lg:left-[1rem] bg-secondary-600;
              }
          }
      }
  }
  </style>
