import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);
const tailwindsColors = fullConfig.theme.colors;
const tailwindsBreakpoints = fullConfig.theme.screens;

export {
    fullConfig,
    tailwindsColors,
    tailwindsBreakpoints
};
