import { render, staticRenderFns } from "./richtext-block.vue?vue&type=template&id=33313590&scoped=true"
import script from "./richtext-block.vue?vue&type=script&lang=js"
export * from "./richtext-block.vue?vue&type=script&lang=js"
import style0 from "./richtext-block.vue?vue&type=style&index=0&id=33313590&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33313590",
  null
  
)

export default component.exports