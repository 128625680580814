<template>
  <div class="richtext-block"
       v-bind:class="[
         {'richtext-block--preview': preview},
         {'richtext-block--left-border': border_left},
         {'richtext-block--left-indent': left_indent}
       ]">

    <label v-if="label" class="richtext-block__label">
      {{label}}
    </label>

    <div v-html="source" class="richtext-block__content ck-content"></div>

  </div>
</template>

<script>
import helpers_filters from '@/mixin/helpers_filters';
export default {
    mixins: [helpers_filters],
    props: {
        read_more_limit: {type: Number},
        preview: {
            type: Boolean,
            default: false
        },
        left_indent: {
            type: Boolean,
            default: false
        },
        border_left: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        source: {type: String}
    },
    data() {
        return {};
    },
    computed: {
        format_source() {
            if (this.read_more_limit && this.source.length >= this.read_more_limit) {
                return this.$options.filters.readMore(
                    this.source, this.read_more_limit, '...'
                );
            } else {
                return this.source;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.ck-content) {
    h1 {
        @apply text-xl lg:text-4xl;
    }

    h2 {
        @apply text-lg lg:text-3xl;
    }

    h3 {
        @apply text-base lg:text-xl;
    }

    h4 {
        @apply text-base lg:text-xl;;
    }

    h5 {
        @apply text-sm lg:text-lg;
    }

    h6 {
        @apply text-sm font-bold;
    }

    ul {
        @apply pl-10 mb-6 list-disc;

        li {}
    }
}
</style>
