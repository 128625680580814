const map_platform_colors = {
    local: 'bg-jinius-red text-white',
    dev: 'bg-jinius-green text-white',
    pilote: 'bg-jinius-blue text-white',
    preprod: 'bg-jinius-dark-green text-white'
};

const map_tag_colors = {
    metier: 'bg-accent-600 text-white',
    type_formation: 'bg-accent-500 text-white',
    thematique: 'bg-accent-700 text-white',
    public_concerne: 'bg-accent-800 text-white'
};

const map_catalogue_searchform_categories = [
    {
        name: 'villes',
        label: 'Villes(s)'
    }
];

const map_catalogue_thematiques_categories = [
    {
        name: 'metiers',
        label: 'Métier(s)'
    },
    {
        name: 'types_formations',
        label: 'Type(s) de formation(s)'
    },
    {
        name: 'thematiques',
        label: 'Thématique(s)'
    }
];

const map_categories_names = [...map_catalogue_searchform_categories, ...map_catalogue_thematiques_categories];

const map_thematiques = {
    'Parcours rebond': 'sort-numeric-up-alt',
    'Programmes prioritaires': 'pen-alt',
    Actualités: ['fab', 'leanpub'],
    'Les cycles': 'recycle',
    Collaborateurs: 'users',
    'Commissariat aux apports ou à la fusion, Evaluation': ['fab', 'mixer'],
    'Déontologie et normes': 'pencil-ruler',
    'Cadre juridique de la mission du CAC': 'gavel',
    'Les associations': 'hands-helping',
    'Audit et contrôle interne': 'chart-line',
    IFRS: 'graduation-cap',
    'Comptes consolidés': 'cubes',
    "Technologie de l'information": ['fab', 'git-alt'],
    'Management et communication': 'tasks',
    "Préparation intensive à l'examen": 'envelope-open-text',
    'Les autres secteurs': 'feather-alt'
};

const map_thematiques_supp = [
    {
        value: 'INF',
        label: 'Système d’information et Numérique'
    },

    {
        value: 'MMC',
        label: 'Management / Marketing / Communication'
    },

    {
        value: 'REV',
        label: 'Comptabilité / Révision'
    },

    {
        value: 'JUR',
        label: 'Juridique'
    },

    {
        value: 'SOC',
        label: 'Social'
    },

    {
        value: 'ACI',
        label: 'Audit et contrôle interne'
    }
];

const map_public_concernes = [
    {
        value: 'CEC',
        label: 'Collaborateur EC'
    },
    {
        value: 'CLI',
        label: 'Client du cabinet'
    },
    {
        value: 'CCC',
        label: 'Collaborateurs confirmés CAC'
    },
    {
        value: 'CDC',
        label: 'Collaborateurs débutants CAC'
    },
    {
        value: 'CAC',
        label: 'Commissaire aux comptes'
    },
    {
        value: 'DIR',
        label: 'Directeurs de mission CAC'
    },
    {
        value: 'ECO',
        label: 'Expert-comptable'
    },
    {
        value: 'CCS',
        label: 'Comm. aux comptes stagiaire'
    },
    {
        value: 'ECS',
        label: 'Expert-comptable stagiaire'
    },
    {
        value: 'MEM',
        label: 'Mémorialiste'
    },
    {
        value: 'DSE',
        label: 'Dirigeant et salarié d’entreprise'
    },
    {
        value: 'CMC',
        label: 'Chefs de mission CAC'
    },
    {
        value: 'EPE',
        label: 'Etudiant et préparation aux examens'
    }
];

const map_types_formations = {
    parcours: ['fab', 'stumbleupon'],
    webinaires: 'network-wired',
    'e-learnings': 'chalkboard-teacher',
    présentiel: 'user',
    'classe virtuelle': 'laptop-house'
};

const map_metiers = {
    'commissaire aux comptes': ['fas', 'thumbs-up'],
    'expert comptable': ['far', 'thumbs-up']
};

const map_villes = [
    {label: 'Agen'},
    {label: 'Ajaccio'},
    {label: 'Amiens'},
    {label: 'Angers'},
    {label: 'Auxerre'},
    {label: 'Bastia'},
    {label: 'Belfort'},
    {label: 'Besançon'},
    {label: 'Biarritz'},
    {label: 'Bordeaux'},
    {label: 'Bourges'},
    {label: 'Brest'},
    {label: 'Caen'},
    {label: 'Calais'},
    {label: 'Cayenne'},
    {label: 'Chambéry'},
    {label: 'Charleville-Mézières'},
    {label: 'Clermont-Ferrand'},
    {label: 'Compiègne'},
    {label: 'Dijon'},
    {label: 'Fort-de-France'},
    {label: 'Gap'},
    {label: 'Grenoble'},
    {label: 'Le Mans'},
    {label: 'Lille'},
    {label: 'Limoges'},
    {label: 'Lyon'},
    {label: 'Marseille'},
    {label: 'Metz'},
    {label: 'Montpellier'},
    {label: 'Nancy'},
    {label: 'Nantes'},
    {label: 'Narbonne'},
    {label: 'Nice'},
    {label: 'Nîmes'},
    {label: 'Niort'},
    {label: 'Orléans'},
    {label: 'Paris'},
    {label: 'Pau'},
    {label: 'Perpignan'},
    {label: 'Pointe-à-Pitre'},
    {label: 'Poitiers'},
    {label: 'Quimper'},
    {label: 'Reims'},
    {label: 'Rennes'},
    {label: 'Rodez'},
    {label: 'Rouen'},
    {label: 'Saint Brieuc'},
    {label: 'Saint-Pierre (La Réunion)'},
    {label: 'Strasbourg'},
    {label: 'Toulouse'},
    {label: 'Troyes'},
    {label: 'Vannes'},
    {label: 'Versailles'}
];
const map_countries = [
    {
        value: 'FR',
        label: 'France'
    },
    {
        value: 'ZA',
        label: 'Afrique du Sud'
    },
    {
        value: 'AL',
        label: 'Albanie'
    },
    {
        value: 'DZ',
        label: 'Algérie'
    },
    {
        value: 'DE',
        label: 'Allemagne'
    },
    {
        value: 'AD',
        label: 'Andorre'
    },
    {
        value: 'AO',
        label: 'Angola'
    },
    {
        value: 'AI',
        label: 'Anguilla'
    },
    {
        value: 'AQ',
        label: 'Antarctique'
    },
    {
        value: 'AG',
        label: 'Antigua-et-Barbuda'
    },
    {
        value: 'AN',
        label: 'Antilles néerlandaises'
    },
    {
        value: 'SA',
        label: 'Arabie Saoudite'
    },
    {
        value: 'AR',
        label: 'Argentine'
    },
    {
        value: 'AM',
        label: 'Arménie'
    },
    {
        value: 'AW',
        label: 'Aruba'
    },
    {
        value: 'AU',
        label: 'Australie'
    },
    {
        value: 'AT',
        label: 'Autriche'
    },
    {
        value: 'AZ',
        label: 'Azerbaïdjan'
    },
    {
        value: 'BS',
        label: 'Bahamas'
    },
    {
        value: 'BH',
        label: 'Bahreïn'
    },
    {
        value: 'BD',
        label: 'Bangladesh'
    },
    {
        value: 'BB',
        label: 'Barbade'
    },
    {
        value: 'BE',
        label: 'Belgique'
    },
    {
        value: 'BZ',
        label: 'Bélize'
    },
    {
        value: 'BJ',
        label: 'Bénin'
    },
    {
        value: 'BM',
        label: 'Bermudes'
    },
    {
        value: 'BT',
        label: 'Bhoutan'
    },
    {
        value: 'BY',
        label: 'Biélorussie'
    },
    {
        value: 'BO',
        label: 'Bolivie'
    },
    {
        value: 'BQ',
        label: 'Bonaire, Saint Eustatius et Saba'
    },
    {
        value: 'BA',
        label: 'Bosnie-Herzégovine'
    },
    {
        value: 'BW',
        label: 'Botswana'
    },
    {
        value: 'BR',
        label: 'Brésil'
    },
    {
        value: 'BN',
        label: 'Brunéi Darussalam'
    },
    {
        value: 'BG',
        label: 'Bulgarie'
    },
    {
        value: 'BF',
        label: 'Burkina Faso'
    },
    {
        value: 'BI',
        label: 'Burundi'
    },
    {
        value: 'KH',
        label: 'Cambodge'
    },
    {
        value: 'CM',
        label: 'Cameroun'
    },
    {
        value: 'CA',
        label: 'Canada'
    },
    {
        value: 'CV',
        label: 'Cap Vert'
    },
    {
        value: 'CL',
        label: 'Chili'
    },
    {
        value: 'CN',
        label: 'Chine'
    },
    {
        value: 'CY',
        label: 'Chypre'
    },
    {
        value: 'CO',
        label: 'Colombie'
    },
    {
        value: 'KM',
        label: 'Comores'
    },
    {
        value: 'CG',
        label: 'Congo'
    },
    {
        value: 'CD',
        label: 'Congo, République démocratique du'
    },
    {
        value: 'KR',
        label: 'Corée, République de'
    },
    {
        value: 'CR',
        label: 'Costa Rica'
    },
    {
        value: 'CI',
        label: 'Côte d\'ivoire'
    },
    {
        value: 'HR',
        label: 'Croatie'
    },
    {
        value: 'CW',
        label: 'Curaçao'
    },
    {
        value: 'DK',
        label: 'Danemark'
    },
    {
        value: 'DJ',
        label: 'Djibouti'
    },
    {
        value: 'DM',
        label: 'Dominique'
    },
    {
        value: 'EG',
        label: 'Égypte'
    },
    {
        value: 'SV',
        label: 'El Salvador'
    },
    {
        value: 'AE',
        label: 'Émirats arabes unis'
    },
    {
        value: 'EC',
        label: 'Équateur'
    },
    {
        value: 'ER',
        label: 'Érythrée'
    },
    {
        value: 'ES',
        label: 'Espagne'
    },
    {
        value: 'EE',
        label: 'Estonie'
    },
    {
        value: 'US',
        label: 'États-Unis'
    },
    {
        value: 'ET',
        label: 'Ethiopie'
    },
    {
        value: 'RU',
        label: 'Fédération de Russie'
    },
    {
        value: 'FJ',
        label: 'Fidji'
    },
    {
        value: 'FI',
        label: 'Finlande'
    },
    {
        value: 'GA',
        label: 'Gabon'
    },
    {
        value: 'GM',
        label: 'Gambie'
    },
    {
        value: 'GE',
        label: 'Géorgie '
    },
    {
        value: 'GS',
        label: 'Géorgie du Sud et les Îles Sandwich du Sud'
    },
    {
        value: 'GH',
        label: 'Ghana'
    },
    {
        value: 'GI',
        label: 'Gibraltar'
    },
    {
        value: 'GR',
        label: 'Grèce'
    },
    {
        value: 'GD',
        label: 'Grenade'
    },
    {
        value: 'GL',
        label: 'Groenland'
    },
    {
        value: 'GP',
        label: 'Guadeloupe'
    },
    {
        value: 'GU',
        label: 'Guam'
    },
    {
        value: 'GT',
        label: 'Guatemala'
    },
    {
        value: 'GN',
        label: 'Guinée'
    },
    {
        value: 'GQ',
        label: 'Guinée équatoriale'
    },
    {
        value: 'GW',
        label: 'Guinée-Bissau'
    },
    {
        value: 'GY',
        label: 'Guyane'
    },
    {
        value: 'GF',
        label: 'Guyane française'
    },
    {
        value: 'HT',
        label: 'Haïti'
    },
    {
        value: 'HN',
        label: 'Honduras'
    },
    {
        value: 'HK',
        label: 'Hong Kong'
    },
    {
        value: 'HU',
        label: 'Hongrie'
    },
    {
        value: 'BV',
        label: 'Île Bouvet'
    },
    {
        value: 'CX',
        label: 'Île Christmas'
    },
    {
        value: 'HM',
        label: 'Île Heard et îles McDonald'
    },
    {
        value: 'NF',
        label: 'Île Norfolk'
    },
    {
        value: 'KY',
        label: 'Îles Caïmans'
    },
    {
        value: 'CC',
        label: 'Îles Cocos (Keeling)'
    },
    {
        value: 'CK',
        label: 'Îles Cook'
    },
    {
        value: 'FK',
        label: 'Îles Falkland (Malouines)'
    },
    {
        value: 'FO',
        label: 'Îles Féroé'
    },
    {
        value: 'MP',
        label: 'Îles Mariannes du Nord'
    },
    {
        value: 'MH',
        label: 'Îles Marshall'
    },
    {
        value: 'UM',
        label: 'Îles mineures éloignées des États-Unis'
    },
    {
        value: 'SB',
        label: 'Îles Salomon'
    },
    {
        value: 'TC',
        label: 'Îles Turques-et-Caïques'
    },
    {
        value: 'VI',
        label: 'Îles Vierges américaines'
    },
    {
        value: 'VG',
        label: 'Îles Vierges britanniques'
    },
    {
        value: 'IN',
        label: 'Inde'
    },
    {
        value: 'ID',
        label: 'Indonésie'
    },
    {
        value: 'IE',
        label: 'Irlande'
    },
    {
        value: 'IS',
        label: 'Islande'
    },
    {
        value: 'IL',
        label: 'Israël'
    },
    {
        value: 'IT',
        label: 'Italie'
    },
    {
        value: 'JM',
        label: 'Jamaïque'
    },
    {
        value: 'JP',
        label: 'Japon'
    },
    {
        value: 'JO',
        label: 'Jordanie'
    },
    {
        value: 'KZ',
        label: 'Kazakhstan'
    },
    {
        value: 'KE',
        label: 'Kenya'
    },
    {
        value: 'KG',
        label: 'Kirghizistan'
    },
    {
        value: 'KI',
        label: 'Kiribati'
    },
    {
        value: 'KW',
        label: 'Koweït'
    },
    {
        value: 'LS',
        label: 'Lesotho'
    },
    {
        value: 'LV',
        label: 'Lettonie'
    },
    {
        value: 'LB',
        label: 'Liban'
    },
    {
        value: 'LR',
        label: 'Liberia'
    },
    {
        value: 'LY',
        label: 'Libye '
    },
    {
        value: 'LI',
        label: 'Liechtenstein'
    },
    {
        value: 'LT',
        label: 'Lituanie'
    },
    {
        value: 'LU',
        label: 'Luxembourg'
    },
    {
        value: 'MO',
        label: 'Macao'
    },
    {
        value: 'MK',
        label: 'Macédoine, Ex-République yougoslave de'
    },
    {
        value: 'MG',
        label: 'Madagascar'
    },
    {
        value: 'MY',
        label: 'Malaisie'
    },
    {
        value: 'MW',
        label: 'Malawi'
    },
    {
        value: 'MV',
        label: 'Maldives'
    },
    {
        value: 'ML',
        label: 'Mali'
    },
    {
        value: 'MT',
        label: 'Malte'
    },
    {
        value: 'MA',
        label: 'Maroc'
    },
    {
        value: 'MQ',
        label: 'Martinique'
    },
    {
        value: 'MU',
        label: 'Maurice'
    },
    {
        value: 'MR',
        label: 'Mauritanie'
    },
    {
        value: 'YT',
        label: 'Mayotte'
    },
    {
        value: 'MX',
        label: 'Mexique'
    },
    {
        value: 'FM',
        label: 'Micronésie, États fédérés de'
    },
    {
        value: 'MC',
        label: 'Monaco'
    },
    {
        value: 'MN',
        label: 'Mongolie'
    },
    {
        value: 'ME',
        label: 'Montenegro'
    },
    {
        value: 'MS',
        label: 'Montserrat'
    },
    {
        value: 'MZ',
        label: 'Mozambique'
    },
    {
        value: 'MM',
        label: 'Myanmar'
    },
    {
        value: 'NA',
        label: 'Namibie'
    },
    {
        value: 'NR',
        label: 'Nauru'
    },
    {
        value: 'NP',
        label: 'Népal'
    },
    {
        value: 'NI',
        label: 'Nicaragua'
    },
    {
        value: 'NE',
        label: 'Niger'
    },
    {
        value: 'NG',
        label: 'Nigeria'
    },
    {
        value: 'NU',
        label: 'Niué'
    },
    {
        value: 'NO',
        label: 'Norvège'
    },
    {
        value: 'NC',
        label: 'Nouvelle-Calédonie'
    },
    {
        value: 'NZ',
        label: 'Nouvelle-Zélande'
    },
    {
        value: 'OM',
        label: 'Oman'
    },
    {
        value: 'UG',
        label: 'Ouganda'
    },
    {
        value: 'UZ',
        label: 'Ouzbékistan'
    },
    {
        value: 'PK',
        label: 'Pakistan'
    },
    {
        value: 'PW',
        label: 'Palaos'
    },
    {
        value: 'PA',
        label: 'Panama'
    },
    {
        value: 'PG',
        label: 'Papouasie-Nouvelle-Guinée'
    },
    {
        value: 'PY',
        label: 'Paraguay'
    },
    {
        value: 'NL',
        label: 'Pays-Bas'
    },
    {
        value: 'PE',
        label: 'Pérou'
    },
    {
        value: 'PH',
        label: 'Philippines'
    },
    {
        value: 'PN',
        label: 'Pitcairn'
    },
    {
        value: 'PL',
        label: 'Pologne'
    },
    {
        value: 'PF',
        label: 'Polynésie française'
    },
    {
        value: 'PR',
        label: 'Porto Rico'
    },
    {
        value: 'PT',
        label: 'Portugal'
    },
    {
        value: 'QA',
        label: 'Qatar'
    },
    {
        value: 'CF',
        label: 'République centrafricaine'
    },
    {
        value: 'MD',
        label: 'République de Moldavie'
    },
    {
        value: 'LA',
        label: 'République démocratique populaire lao'
    },
    {
        value: 'DO',
        label: 'République dominicaine'
    },
    {
        value: 'CZ',
        label: 'République Tchèque'
    },
    {
        value: 'RE',
        label: 'Réunion'
    },
    {
        value: 'RO',
        label: 'Roumanie'
    },
    {
        value: 'GB',
        label: 'Royaume-Uni'
    },
    {
        value: 'RW',
        label: 'Rwanda'
    },
    {
        value: 'EH',
        label: 'Sahara occidental'
    },
    {
        value: 'KN',
        label: 'Saint-Kitts-et-Nevis'
    },
    {
        value: 'SM',
        label: 'Saint-Marin'
    },
    {
        value: 'SX',
        label: 'Saint-Martin'
    },
    {
        value: 'PM',
        label: 'Saint-Pierre-et-Miquelon'
    },
    {
        value: 'VA',
        label: 'Saint-Siège'
    },
    {
        value: 'VC',
        label: 'Saint-Vincent-et-les Grenadines'
    },
    {
        value: 'SH',
        label: 'Sainte-Hélène'
    },
    {
        value: 'LC',
        label: 'Sainte-Lucie'
    },
    {
        value: 'WS',
        label: 'Samoa'
    },
    {
        value: 'AS',
        label: 'Samoa américaines'
    },
    {
        value: 'ST',
        label: 'Sao-Tomé-et-Principe'
    },
    {
        value: 'SN',
        label: 'Sénégal'
    },
    {
        value: 'RS',
        label: 'Serbie'
    },
    {
        value: 'SC',
        label: 'Seychelles'
    },
    {
        value: 'SL',
        label: 'Sierra Leone'
    },
    {
        value: 'SG',
        label: 'Singapour'
    },
    {
        value: 'SK',
        label: 'Slovaquie'
    },
    {
        value: 'SI',
        label: 'Slovénie'
    },
    {
        value: 'SO',
        label: 'Somalie'
    },
    {
        value: 'LK',
        label: 'Sri Lanka'
    },
    {
        value: 'SE',
        label: 'Suède'
    },
    {
        value: 'CH',
        label: 'Suisse'
    },
    {
        value: 'SR',
        label: 'Surinam'
    },
    {
        value: 'SJ',
        label: 'Svalbard et Jan Mayen'
    },
    {
        value: 'SZ',
        label: 'Swaziland'
    },
    {
        value: 'TJ',
        label: 'Tadjikistan'
    },
    {
        value: 'TW',
        label: 'Taïwan'
    },
    {
        value: 'TZ',
        label: 'Tanzanie, République unie de'
    },
    {
        value: 'TD',
        label: 'Tchad'
    },
    {
        value: 'TF',
        label: 'Terres australes et antarctiques françaises'
    },
    {
        value: 'IO',
        label: 'Territoire britannique de l\'océan Indien'
    },
    {
        value: 'TH',
        label: 'Thaïlande'
    },
    {
        value: 'TL',
        label: 'Timor-leste'
    },
    {
        value: 'TG',
        label: 'Togo'
    },
    {
        value: 'TK',
        label: 'Tokelau'
    },
    {
        value: 'TO',
        label: 'Tonga'
    },
    {
        value: 'TT',
        label: 'Trinité-et-Tobago'
    },
    {
        value: 'TN',
        label: 'Tunisie'
    },
    {
        value: 'TM',
        label: 'Turkménistan'
    },
    {
        value: 'TR',
        label: 'Turquie'
    },
    {
        value: 'TV',
        label: 'Tuvalu'
    },
    {
        value: 'UA',
        label: 'Ukraine'
    },
    {
        value: 'UY',
        label: 'Uruguay'
    },
    {
        value: 'VU',
        label: 'Vanuatu'
    },
    {
        value: 'VE',
        label: 'Venezuela'
    },
    {
        value: 'VN',
        label: 'Vietnam'
    },
    {
        value: 'WF',
        label: 'Wallis-et-Futuna'
    },
    {
        value: 'YE',
        label: 'Yémen'
    },
    {
        value: 'ZM',
        label: 'Zambie'
    },
    {
        value: 'ZW',
        label: 'Zimbabwe'
    }
];

const map_monthes = [
    {
        id: 1,
        label: 'Janvier'
    },
    {
        id: 2,
        label: 'Février'
    },
    {
        id: 3,
        label: 'Mars'
    },
    {
        id: 4,
        label: 'Avril'
    },
    {
        id: 5,
        label: 'Mai'
    },
    {
        id: 6,
        label: 'Juin'
    },
    {
        id: 7,
        label: 'Juillet'
    },
    {
        id: 8,
        label: 'Aout'
    },
    {
        id: 9,
        label: 'Septembre'
    },
    {
        id: 10,
        label: 'Octobre'
    },
    {
        id: 11,
        label: 'Novembre'
    },
    {
        id: 12,
        label: 'Décembre'
    }
];

const map_domain_per_env = {
    local: 'local.test',
    dev: 'safir.dev',
    pilote: 'safir-pilote.fr',
    preprod: 'safir-preprod.fr',
    prod: 'jinius.fr'
};

export {
    map_domain_per_env,
    map_platform_colors,
    map_tag_colors,
    map_categories_names,
    map_catalogue_searchform_categories,
    map_catalogue_thematiques_categories,
    map_thematiques,
    map_thematiques_supp,
    map_public_concernes,
    map_types_formations,
    map_metiers,
    map_villes,
    map_countries,
    map_monthes
};
