<template>
  <div>
    <header class="header">
      <!-- Header's top section -->
      <div class="header__inner header__inner--start"
           v-bind:class="`is-${$current_app_of_key}`">
        <div class="header__wrap header__wrap--top-left">
          <div class="header__item">
            <header-platform-stamp/>
          </div>
          <transition name="fade" mode="out-in">
            <div v-if="$route.name === 'fiche' && $breakpoints.w < $breakpoints.screens.lg"
                 class="header__item px-3">
              <router-link v-bind:to="{name: 'catalogue'}"
                           class="btn-back__link">
                <font-awesome-icon class="link__icon"
                                   v-bind:class="{'text-white': $is_cfpc}"
                                   icon="chevron-left"
                                   size="lg"/>
              </router-link>
            </div>
          </transition>

          <div v-if="($is_cncc || $is_cfpc) && ($breakpoints.w < $breakpoints.screens.lg)"
               v-on:click="toogleSideBar"
               class="header__item p-3">
            <button class="menu-btn">
              <font-awesome-icon v-bind:icon="sidebar ? 'times' : 'bars'"
                                 size="lg"
                                 class="align-middle"
                                 v-bind:class="{'text-white': $is_cfpc}"/>
            </button>
          </div>

          <div v-if="$is_cncc" class="header__item">
            <header-access-espace class="header__link"/>
          </div>
        </div>

        <div class="header__wrap header__wrap--top-center">
          <div class="header__item"
               v-bind:class="{
                 'xl:absolute xl:inset-0 py-2 px-3 lg:px-12': $is_cncc,
                 'absolute inset-0 lg:py-0 py-1': $is_cfpc,
                 'xl:absolute xl:inset-0 py-2 lg:py-0': $is_irf_ceeca,
                 'absolute inset-0 py-2': !$is_cncc && !$is_cfpc && !$is_irf_ceeca
               }">
            <header-logo/>
          </div>
        </div>

        <div class="header__wrap header__wrap--top-right"
             v-bind:class="{'header__wrap--top-right--is-cfpc': $is_cfpc}">
          <div class="header__item">
            <header-cart-button class="header__link"/>
          </div>
          <div class="header__item">
            <header-account-button class="header__link"/>
          </div>
        </div>
      </div>

      <!-- Header's bottom section -->
      <div class="header__inner header__inner--end">
        <transition name="slow-up">
          <header-navbar v-if="($is_cncc || $is_cfpc) && ($breakpoints.w >= $breakpoints.screens.lg)"/>
        </transition>
        <header-sidebar v-if="($is_cncc || $is_cfpc) && sidebar"
                        v-on:close="closeSideBar"
                        class="header-navbar--alt"/>
      </div>
    </header>
  </div>
</template>

<script>
import headerSidebar from '@/components/header/header-sidebar.vue';
import headerPlatformStamp from '@/components/header/header-platform-stamp.vue';
import headerLogo from '@/components/header/header-logo.vue';
import headerCartButton from '@/components/header/header-cart-button.vue';
import headerAccountButton from '@/components/header/header-account-button.vue';
import headerNavbar from '@/components/header/header-navbar.vue';
import headerAccessEspace from '@/components/header/header-access-espace.vue';

import {createNamespacedHelpers} from 'vuex';
const {
    mapState: mapStateObserver, mapMutations: mapMutationsObserver
} = createNamespacedHelpers('observer');

export default {
    components: {
        headerSidebar,
        headerPlatformStamp,
        headerLogo,
        headerCartButton,
        headerAccountButton,
        headerNavbar,
        headerAccessEspace
    },
    computed: {...mapStateObserver({sidebar: (state) => state.sidebar})},
    watch: {
        $route(to, from) {
            this.closeSideBar();
        }
    },
    beforeDestroy() {
        this.closeSideBar();
    },
    methods: {
        ...mapMutationsObserver({setSidebar: 'setSidebar'}),
        toogleSideBar() {
            this.setSidebar(!this.sidebar);
        },
        closeSideBar() {
            this.setSidebar(false);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.header {
    @apply flex flex-wrap w-full;

    .header__inner {
        @apply inline-flex h-full flex-wrap w-full items-center;

        &.header__inner--start {
            @apply relative h-20 justify-between z-10 bg-white;

            &.is-cncc {
              @apply bg-primary-900 text-core-100;
            }

            &.is-cfpc {
              @apply bg-primary-900;

              @screen lg {
                @apply h-36 bg-white;
              }
            }

            &.is-irf-ceeca {
              @apply bg-secondary-500;
            }
        }
    }

    .header__wrap {
        @apply h-full inline-flex;

        &.header__wrap--top-left,
        &.header__wrap--top-right {
            @apply z-50;
            &.header__wrap--top-right--is-cfpc {
              @media (max-width: 1023px) {
                .header__link {
                  @apply text-white #{!important};
                }
              }
              @screen lg {
                @apply flex-col-reverse;
              }
            }
        }

        &.header__wrap--top-center {
          @apply flex-1 items-center justify-center;
        }

        .header__item {
            @apply h-full flex flex-wrap items-center;

            .header__link {
                @apply w-full h-full inline-flex items-center ;
                @include text-link();
            }
        }

    }
}
</style>