<template>
  <article class="accueil">

    <template v-if="$route.name == 'accueil'">
      <div>
        <div class="accueil__inner">
          <div class="inner__row">
            <div class="inner__col">
              <section-box title="A la une"
                           icon="bullhorn"
                           section_class="w-full flex flex-col mb-2 md:mb-4">
                <carousel-slide v-bind:slides="slides"
                                thumbnails
                                v-bind:background_color="bg_color"/>
              </section-box>
              <section-box title="Actualités"
                           icon="newspaper"
                           section_class="w-full flex flex-col mb-2 md:mb-4">
                <info-card v-for="actualite in actualites"
                           v-if="actualite.visible"
                           v-bind:key="actualite.id"
                           v-bind:image="actualite.image"
                           v-bind:title="actualite.titre"
                           v-bind:description="actualite.description"
                           v-bind:href="`/actualite/${actualite.id}`"/>
              </section-box>
            </div>
            <div class="inner__col">
              <section-box title="Focus sur les formations"
                           icon="graduation-cap"
                           section_class="w-full flex flex-col flex-grow mb-2 md:mb-4">
                <info-card v-for="formation in formations"
                           v-if="formation.visible"
                           v-bind:key="formation.id"
                           v-bind:title="formation.titre"
                           v-bind:description="formation.description"
                           v-bind:href="`${formation.url}`"
                           v-bind:is_external="true"/>
              </section-box>
              <section-box title="Ressources"
                           icon="folder-open"
                           section_class="w-full flex flex-col self-end mb-2 md:mb-4"
                           see_more_text="Voir les ressources"
                           see_more_href="/ressources">
                <div class="-mt-4"></div>
                <router-link to="/ressources" class="cursor-pointer flex items-center justify-center mt-4 mb-2 ressources">
                  <div class="w-40 h-40 flex items-center justify-center rounded-full p-4 ressources__icon">
                    <font-awesome-icon icon="copy"
                                       size="6x"
                                       class=""/>
                  </div>
                </router-link>
              </section-box>
            </div>
          </div>
        </div>
        <display-chiffres-cles v-bind:chiffres="chiffres"/>
      </div>
    </template>

  </article>
</template>

<script>
import {CMS} from '@/http-common';

import carouselSlide from '@/components/utils/carousel-slide.vue';
import sectionBox from '@/components/utils/section-box.vue';
import infoCard from '@/components/utils/info-card.vue';
import displayChiffresCles from '@/components/utils/display-chiffres-cles.vue';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config.js';
const fullConfig = resolveConfig(tailwindConfig);

export default {
    components: {
        carouselSlide,
        sectionBox,
        infoCard,
        displayChiffresCles
    },
    data() {
        return {
            slides: null,
            actualites: null,
            formations: null,
            chiffres: null
        };
    },
    computed: {
        bg_color() {
            return fullConfig.theme.backgroundColor.core[200];
        },
        catalogue_link() {
            return `https://${process.env.VUE_APP_HOST_CATALOGUE}`;
        }
    },
    created() {
        this.fetchSlides();
        this.fetchActualites();
        this.fetchFormations();
        this.fetchChiffres();
    },
    methods: {
        async fetchSlides() {
            try {
                const carousel = await CMS.get('/home-carousel');

                this.slides = carousel.data.slides;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        },
        async fetchActualites() {
            try {
                const actualites = await CMS.get('/actualites?_sort=priorite:ASC');

                this.actualites = actualites.data;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        },
        async fetchFormations() {
            try {
                const formations = await CMS.get('/formations?_sort=priorite:ASC');

                this.formations = formations.data;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        },
        async fetchChiffres() {
            try {
                const chiffres = await CMS.get('/home-chiffres');

                this.chiffres = chiffres.data.chiffres;
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        }
        /* goToRessources() {
            this.$router.push('/ressources');
        }, */
    }
};
</script>
<style lang="scss" scoped>
.accueil{
  @apply bg-primary-900;
  .accueil__inner {
    @apply w-full flex md:flex-wrap md:flex-row;

    .inner__row {
      @apply w-full flex flex-col md:flex-row md:space-x-4 md:mx-0;

      .inner__col {
        @apply w-full md:w-1/2 flex flex-wrap md:flex-col items-stretch;
      }
    }
  }
}
.ressources {
  .ressources__icon {
    @apply bg-primary-700 text-white;
  }

  &:hover {
  .ressources__icon {
      @apply bg-primary-900;
    }
  }
}
</style>
