// tailwind.config.js
'use strict';

/*=============================================>>>>>
= Tailwind Default Theme Colors =
===============================================>>>>>*/
const colors = require('tailwindcss/colors');
delete colors['lightBlue'];
delete colors['warmGray'];
delete colors['trueGray'];
delete colors['coolGray'];
delete colors['blueGray'];

const {extra_palettes} = require('./src/assets/data/data_colors');

const available_palettes = Object.assign({}, colors, extra_palettes);

/*=============================================>>>>>
= Setting colors from .env configuration file =
===============================================>>>>>*/
const CONFIG_CORE = process.env.VUE_APP_CORE_COLOR && available_palettes[process.env.VUE_APP_CORE_COLOR] ? available_palettes[process.env.VUE_APP_CORE_COLOR] : available_palettes.core;

const CONFIG_PRIMARY = process.env.VUE_APP_PRIMARY_COLOR && available_palettes[process.env.VUE_APP_PRIMARY_COLOR] ? available_palettes[process.env.VUE_APP_PRIMARY_COLOR] : available_palettes.sky;

const CONFIG_SECONDARY = process.env.VUE_APP_SECONDARY_COLOR && available_palettes[process.env.VUE_APP_SECONDARY_COLOR] ? available_palettes[process.env.VUE_APP_SECONDARY_COLOR] : available_palettes.slate;

/*=============================================>>>>>
= Tailwind Configuration =
===============================================>>>>>*/
module.exports = {
    content: [
        './src/**/*.{html,vue}',
        './src/assets/data/data_maps.js',
    ],
    safelist: [
        'bg-jinius-red',
        'bg-jinius-green',
        'bg-jinius-blue',
        'bg-jinius-dark-green',
        'bg-jinius-purple',
        'bg-jinius-yellow',
        'bg-jinius-pink',
        'lg:w-1/2',
        'lg:w-1/3',
        'lg:w-1/4',
        'lg:w-1/5',
        'lg:w-1/6',
        {
            pattern: /(bg|border|text)-(secondary|teal|blue|cyan|orange|purple|rose|green|danger|accent)-(600)/,
            variants: ['group-hover', 'hover'],
        }
    ],
    theme: {
        fontFamily: {
            sans: ['Roboto', 'Helvetica', 'ui-sans-serif', 'system-ui', 'sans-serif']
        },
        colors: {
            current: 'currentColor',

            core: CONFIG_CORE,
            primary: CONFIG_PRIMARY,
            secondary: CONFIG_SECONDARY,

            accent: available_palettes.gray,
            success: available_palettes.custom_greenVivid,
            info: available_palettes.custom_blue,
            danger: available_palettes.custom_redVivid,
            white: available_palettes.white,
            black: available_palettes.black,
            transparent: available_palettes.transparent,
            yellow: available_palettes.custom_yellowVivid,
            orange: available_palettes.custom_orangeVivid,
            cyan: available_palettes.custom_cyan,
            teal: available_palettes.custom_teal,
            purple: available_palettes.custom_purple,
            blue: available_palettes.custom_blue,
            green: available_palettes.custom_green,
            rose: available_palettes.rose,

            'jinius-green': available_palettes['jinius-green'],
            'jinius-dark-green':available_palettes['jinius-dark-green'],
            'jinius-blue':available_palettes['jinius-blue'],
            'jinius-red':available_palettes['jinius-red'],
            'jinius-purple':available_palettes['jinius-purple'],
            'jinius-yellow':available_palettes['jinius-yellow'],
            'jinius-pink':available_palettes['jinius-pink'],
        },
        extend: {
            spacing: {
                72: '18rem',
                84: '21rem',
                96: '24rem',
                108: '27rem',
                120: '30rem',
                132: '33rem',
                144: '36rem',
                156: '39rem',
                168: '42rem',
            },
            borderWidth: {
                1: '1px'
            },
            skew: {
                '-30': '-30deg',
            }
        }
    }
};
