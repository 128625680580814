<template>
  <div v-if="$breakpoints.w >= $breakpoints.screens.lg"
       class="relative flex">
    <div v-if="is_stamp"
         class="platform-stamp"
         v-bind:class="map_platform_colors[$current_app_context]">
      <div class="flex flex-wrap">
        <span class="w-full mb-1">Env</span>
        <span class="w-full font-bold">
          {{$current_app_context}}
        </span>
      </div>
    </div>
    <div v-if="$is_cncc" class="flex items-center ml-3 lg:mx-6">
      <img src="@/assets/img/jinius.svg" class="inline-flex w-12 h-12 bg-white px-1.5 object-fill"/>
    </div>

  </div>
</template>

<script>
import {map_platform_colors} from '@/assets/data/data_maps.js';

export default {
    data() {
        return {map_platform_colors};
    },
    computed: {
        is_stamp() {
            return !['prod', 'local'].includes(this.$current_app_context);
        }
    }
};
</script>

<style lang="scss" scoped>
 .platform-stamp {
    @apply rounded-full flex self-center text-center leading-none w-16 h-16 items-center justify-center   shadow-md;
}
</style>