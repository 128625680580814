var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center items-center"},[_c('form',{staticClass:"pt-6 px-6 flex flex-col",attrs:{"method":"post"}},[_c('div',{staticClass:"flex items-center justify-between text-sm"},[_c('a',{staticClass:"w-full flex h-16 items-center justify-center rounded shadow hover:shadow-md uppercase leading-none bg-jinius-dark-green/80 text-white hover:bg-opacity-90 font-bold py-5 px-8 focus:outline-none transition duration-150 ease-in-out",attrs:{"href":_vm.connectWithOidcProvider},on:{"click":_vm.triggerRedirectPath}},[_vm._m(0),_vm._v(" "),_c('span',[_vm._v("Me connecter à JINIUS")])])]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center text-sm mt-4"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.is_navbar ? {
           placement: 'bottom',
           offset: '10',
           /* show:true, */
           /* autoHide:false, */
           content: _vm.safirInfo()
         } : null),expression:"is_navbar ? {\n           placement: 'bottom',\n           offset: '10',\n           /* show:true, */\n           /* autoHide:false, */\n           content: safirInfo()\n         } : null"}],staticClass:"w-full flex h-16 items-center justify-center rounded shadow hover:shadow-md uppercase leading-none bg-white text-accent-700 hover:bg-opacity-90 font-bold py-5 px-8 focus:outline-none transition duration-150 ease-in-out",attrs:{"target":"_blank","href":_vm.createNewAccount}},[_c('span',[_vm._v("Créer un compte")]),_vm._v(" "),_c('img',{staticClass:"inline-flex w-16 py-1 px-2",attrs:{"src":require("@/assets/img/jinius.svg"),"alt":"Jinius"}}),_vm._v(" "),(_vm.is_navbar)?_c('font-awesome-icon',{attrs:{"size":"1x","icon":['fas', 'info-circle']}}):_vm._e()],1)])]),_vm._v(" "),(!_vm.is_navbar)?_c('div',{staticClass:"flex flex-col text-left ml-2 pl-6 border-core-400 border-l-1",domProps:{"innerHTML":_vm._s(_vm.safirInfo())}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-5 h-5 mr-3"},[_c('img',{staticClass:"filter brightness-125",attrs:{"src":require("@/assets/img/favicon.svg"),"alt":"..."}})])
}]

export { render, staticRenderFns }