<template>
  <img class="logo-image"
       v-bind:src="src"/>
</template>
<script>
export default {props: {src: {type: String}}};
</script>
<style lang="scss" scoped>
  .logo-image {
    @apply w-full h-full mx-auto object-contain;
  }
</style>
