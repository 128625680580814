<template>
  <div class="section__wrapper"
       v-bind:class="section_class">
    <div class="section__header">
      <div v-if="title"
           class="section__headline">
        <font-awesome-icon v-if="icon"
                           class="headline__icon"
                           v-bind:icon="icon"
                           v-bind:size="$breakpoints.w >= $breakpoints.screens.lg ?'2x' : 'lg'"/>
        <h1 class="headline__title">
          {{title}}
        </h1>
      </div>
      <a v-if="see_more_text && see_more_href"
         class="section__link"
         v-bind:href="see_more_href">
        {{see_more_text}}
      </a>
    </div>
    <div class="section__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        section_class: {
            type: String,
            default: ''
        },
        see_more_text: {
            type: String,
            default: null
        },
        see_more_href: {
            type: String,
            default: null
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.section__wrapper{
  @apply shadow bg-primary-100 sm:rounded-md px-4 py-6 lg:p-8;
  .section__header{
    @apply flex items-center justify-between mb-4;
    .section__headline {
      @apply flex items-center;

      .headline__icon {
        @apply text-primary-800 mr-4;
      }
      .headline__title {
        @apply text-primary-800 text-xl sm:text-2xl font-bold uppercase;
      }
    }
    .section__link{
      @apply block text-center text-sm text-primary-800 cursor-pointer hover:underline ml-2;
    }
  }
}
</style>
