import router from '@/router/index';

import axios from 'axios';

const baseURLAuth = process.env.VUE_APP_API_AUTH;
const baseURLApi = process.env.VUE_APP_API_SAFIR;
const baseURLCms = process.env.VUE_APP_API_CMS;

function createRangeArray(start, end) {
    return [
        ...Array(end - start + 1)
            .keys()
    ].map(x => x + start);
}

const CLIENT_ERROR_RANGE = createRangeArray(400, 414);
const SERVER_ERROR_RANGE = createRangeArray(500, 511);

const error_catch_list = [...CLIENT_ERROR_RANGE, ...SERVER_ERROR_RANGE];

/*=============================================>>>>>
= Init Axios calls to OIDC through SAFIR Front-server =
===============================================>>>>>*/
const AUTH = axios.create({
    baseURL: baseURLAuth,
    withCredentials: true,
    headers: {'Content-Type': 'application/json'}
});

AUTH.interceptors.response.use(null, (error) => {
    const has_status_code = !!(error && error.response && error.response.status);

    if (has_status_code && error.response.status === 401) {
        return false;
    }

    // eslint-disable-next-line no-console
    console.error(
        `Error AUTH : Code ${has_status_code ? error.response.status : 'inconnue'}`, {...error}, error.toJSON()
    );

    if (has_status_code && error_catch_list.includes(error.response.status)) {
        const error_number = has_status_code ? error.response.status : null;

        router.push({
            name: 'error-authentification',
            params: {error_number}
        });
    } else if (!has_status_code && error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.error('Code d\'erreur du serveur d\'AUTH manquant');

        return false;
    }

    return Promise.reject(error);
});

/*=============================================>>>>>
= Init Axios calls to STRAPI CMS API =
===============================================>>>>>*/
const CMS = axios.create({
    baseURL: baseURLCms,
    withCredentials: true,
    headers: {'Content-Type': 'application/json'}
});

// Add a response interceptor
CMS.interceptors.response.use(null, (error) => {
    const has_status_code = !!(error && error.response && error.response.status);

    if (has_status_code && error.response.status === 401) {
        return false;
    }

    // eslint-disable-next-line no-console
    console.error(
        `Error CMS : Code ${has_status_code ? error.response.status : 'inconnue'}`, {...error}, error.toJSON()
    );

    if (has_status_code && error_catch_list.includes(error.response.status)) {
        const error_number = has_status_code ? error.response.status : null;

        router.push({
            name: 'error-cms',
            params: {error_number}
        });
    } else if (!has_status_code && error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.error('Code d\'erreur du serveur d\'CMS manquant');

        return false;
    }

    return Promise.reject(error);
});

/*=============================================>>>>>
= Init Axios calls to SAFIR API =
===============================================>>>>>*/
const API = axios.create({
    baseURL: baseURLApi,
    withCredentials: true,
    headers: {'Content-Type': 'application/json'}
});

API.interceptors.response.use(null, (error) => {
    const has_status_code = !!(error && error.response && error.response.status);

    const skipRedirection = error.response.config && error.response.config.headers['SKIP-REDIRECTION'];

    // eslint-disable-next-line no-console
    console.error(
        `Error API : Code ${has_status_code ? error.response.status : 'inconnue'}`, {...error}, error.toJSON()
    );

    if (has_status_code && error_catch_list.includes(error.response.status)) {
        const error_number = has_status_code ? error.response.status : null;

        if (!skipRedirection) {
            router.push({
                name: 'error-server',
                params: {error_number}
            });
        }
    } else if (!has_status_code && error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.error('Code d\'erreur du serveur d\'API manquant');

        router.push({
            name: 'error-server',
            params: {error_number: 502}
        });
    }

    return Promise.reject(error);
});

export {
    AUTH, API, CMS
};
