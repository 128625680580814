const nav_menu = [
    {
        path: '/',
        name: 'accueil',
        meta: {
            label: 'Accueil',
            icon: 'home'
        }
    },
    {
        path: '/qui-sommes-nous',
        name: 'qui-sommes-nous',
        meta: {
            label: 'Qui sommes-nous ?',
            icon: 'address-card'
        }
    },
    {
        path: '/dans-vos-locaux',
        name: 'dans-vos-locaux',
        meta: {
            label: 'Dans vos locaux',
            icon: 'users'
        }
    },
    {
        name: 'catalogue',
        meta: {
            external_path: `https://${process.env.VUE_APP_HOST_CATALOGUE}/`,
            external_same_tab: true,
            label: 'Catalogue en ligne',
            icon: 'search-plus'
        }
    },
    {
        path: '/ressources',
        name: 'ressources',
        meta: {
            label: 'Ressources',
            icon: 'folder-open'
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        meta: {
            label: 'Contacts',
            icon: 'phone-alt',
            flip: 'horizontal'
        }
    }
];

export default {nav_menu};
