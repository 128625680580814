<template>
  <div class="access-espace-dropdown">
    <div class="h-full">
      <div v-click-outside="closeOpener"
           v-on:click="opener = !opener"
           class="access-espace-dropdown__button"
           v-bind:class="[
             {'access-espace-dropdown__button--open': opener}
           ]">
        <span class="button__label">
          <text-button icon="house-user"
                       size="lg"
                       width="2rem"
                       height="2rem"
                       label_margin="2"
                       label="Mes espaces"
                       v-bind:class="{'active': opener}"/>
        </span>
        <span class="button__caret">
          <svg class="caret__svg"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
          </svg>
        </span>
      </div>

      <div class="access-espace-dropdown__list">
        <transition name="slide-up">
          <div v-show="opener" class="list__wrapper">
            <div class="list__item"
                 v-tooltip="{content: 'Sessions de formation avant le 01/07/2021 <br>et e-learning avant le 04/12/2021', classes: 'max-w-sm'}"
                 v-on:click="openAccessEspace('ancien')">
              <text-button
                label="Accéder à mes anciens espaces"
                size="lg"
                icon="house-user"
                width="2rem"
                height="2rem"
                v-bind:dark="false"
                label_margin="4"
                v-bind:external_link="true"
                class="item__link"/>
            </div>
            <div class="list__item"
                 v-on:click="openAccessEspace('jinius')">
              <text-button
                label="Accéder à mes espaces Jinius"
                size="lg"
                icon="house-user"
                width="2rem"
                height="2rem"
                v-bind:dark="false"
                label_margin="4"
                v-bind:external_link="true"
                class="item__link"/>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import {map_domain_per_env} from '@/assets/data/data_maps.js';
import vClickOutside from 'v-click-outside';
import textButton from '@/components/utils/text-button.vue';

export default {
    components: {textButton},
    directives: {clickOutside: vClickOutside.directive},
    data() {
        return {
            opener: false,
            map_domain_per_env
        };
    },
    methods: {
        closeOpener() {
            this.opener = false;
        },
        openAccessEspace(key) {
            this.opener = false;

            const ancien = ['local', 'dev'].includes(process.env.VUE_APP_CONTEXT) ? `https://ancien-${process.env.VUE_APP_HOST_WWW}/espace` : `https://ancien.${process.env.VUE_APP_HOST_WWW}/espace`;

            const jinius = `https://${process.env.VUE_APP_HOST_ESPACE}`;

            const url = {
                ancien,
                jinius
            };

            window.open(url[key]);
        }
    }
};
</script>

<style lang="scss" scoped>
.access-espace-dropdown {
    @apply w-full h-full flex items-center flex-wrap max-w-sm;

    .access-espace-dropdown__button {
        @apply cursor-pointer px-3 w-full h-full flex transition duration-200 ease-in-out hover:bg-accent-600/10;

        &.access-espace-dropdown__button--open {
            @apply bg-accent-600/10;
        }

        .button__label {
            @apply w-[calc(100%-1rem)] flex items-center;
            :deep(.text-link__name) {
                @apply hidden lg:flex;
            }
        }

        .button__caret {
            @apply flex w-4 pointer-events-none items-center justify-center;

            .caret__svg {
                @apply fill-current flex h-4 w-4;
            }
        }
    }

    .access-espace-dropdown__list {
        @apply w-full h-0 relative;

        .list__wrapper {
            @apply absolute left-[-20px] lg:left-0 right-0 top-0 flex flex-wrap mt-1 rounded-lg shadow text-core-600 bg-core-100 z-20 font-normal overflow-auto space-y-1 min-w-[305px] w-auto;

            .list__item {
                @apply w-full h-16 flex items-center hover:bg-accent-200 hover:text-core-900;

                .item__link {
                    @apply w-full cursor-pointer active:shadow-inner px-3 transition duration-150 ease-in-out;
                }
            }
        }
    }
}

:deep(.text-link) {
    @apply justify-start;

    .fill-current,
    .text-link__name {
        @apply flex-1 pr-3;
        color: inherit;
    }
}
</style>
