<template>
  <logo-image v-bind:src="fetchLogo"
              class="h-full"/>
</template>

<script>
import {of_list} from '@/assets/data/data_of_logo.js';

import logoImage from '@/components/utils/logo-image.vue';

export default {
    components: {logoImage},
    props: {
        alt: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        fetchLogo() {
            let logo_url;
            const logo = process.env.VUE_APP_LOGO;
            const banniere = process.env.VUE_APP_BANNER ? process.env.VUE_APP_BANNER : process.env.VUE_APP_LOGO;

            const current_of = of_list[process.env.VUE_APP_OF_KEY];

            const is_breakpoint = this.$breakpoints.w >= this.$breakpoints.screens[current_of.breakpoint];

            const fetchTypeOfImg = (type) => {
                return type === 'banniere' ? banniere : logo;
            };

            const fetched_logo = is_breakpoint ? fetchTypeOfImg(current_of.desktop) : fetchTypeOfImg(current_of.mobile);

            if (process.env.VUE_APP_OF_KEY === 'irf-cfpca') {
                const timestamp = new Date()
                    .getTime();

                logo_url = `${fetched_logo}?t=${timestamp}`;
            } else {
                logo_url = fetched_logo;
            }

            return logo_url;
        }
    }
};
</script>
