import Vue from 'vue';
import VueMatomo from 'vue-matomo';

import App from './App.vue';
import router from './router';
import store from './store';

import './vue-tooltip';
import './vue-toasted';
import './vue-fontawesome';
import './vue-validate';

import './assets/css/tailwind.scss';

import Cookies from 'js-cookie';
function listenCookieChange(callback, interval = 500) {
    let lastCookie = Cookies.get('auth_status');

    setInterval(()=> {
        const cookie = Cookies.get('auth_status');

        if (cookie !== lastCookie) {
            try {
                callback({
                    oldValue: lastCookie,
                    newValue: cookie
                });
            } finally {
                lastCookie = cookie;
            }
        }
    }, interval);
}
listenCookieChange(()=> {
    // eslint-disable-next-line no-console
    console.log('Synchronizing Auth');
    store.dispatch('auth/applyAuthenticationStatus');
});

// Polyfill pour la gestion moderne en JS de scroll() dans Edge/IE/Safari/Opera
// https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
// https://github.com/iamdustan/smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

Vue.config.productionTip = false;

Vue.prototype.$current_app_type = 'www';
Vue.prototype.$current_app_context = process.env.VUE_APP_CONTEXT;

Vue.prototype.$current_app_of_key = process.env.VUE_APP_OF_KEY;

import snakeCase from 'lodash.snakecase';
import {of_list} from './assets/data/data_of_logo';
Object.keys(of_list)
    .map((key) => {
        const is_current = `$is_${snakeCase(key)}`;

        return Vue.prototype[is_current] = process.env.VUE_APP_OF_KEY === key;
    });

import breakpoints from '@/vue-tailwind-breakpoints';
Vue.prototype.$breakpoints = breakpoints;

Vue.prototype.$header_skip_redirection = {headers: {'SKIP-REDIRECTION': true}};
store.$header_skip_redirection = Vue.prototype.$header_skip_redirection;

// ROUTER BEFORE EACH -> BEFORE VM INSTANCE
router.beforeEach(async(
    to, from, next
) => {
    // Check the authentication status (OIDC Client)
    if (!store.state.auth.authenticated) {
        await store.dispatch('auth/applyAuthenticationStatus');
    }

    const isAuthenticated = store.state.auth.authenticated;

    const isLoginPage = from.matched.some((record) => record.name === 'connexion');
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    // console.log(requiresAuth, isLoginPage, isAuthenticated);

    // console.log('REDIRECT STATE', store.state.auth.redirect_route_path);
    if (requiresAuth && !isAuthenticated) {
        if (!isLoginPage) {
            store.commit('auth/setRedirectUrlPath', to.fullPath);
            next({name: 'connexion'});
        } else {
            next();
        }
    } else {
        next();
    }
});

//  VM INSTANCE
const vm = new Vue({
    router,
    store,
    render: (h) => h(App)
});

// INITIALIZE MATOMO FOR CNCC ENVIRONEMENT
if (Vue.prototype.$is_cncc && process.env.VUE_APP_MATOMO_HOST && process.env.VUE_APP_MATOMO_SITE_ID) {
    Vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: process.env.VUE_APP_MATOMO_HOST,
        siteId: process.env.VUE_APP_MATOMO_SITE_ID,
        router
    });
    window._paq.push(['trackPageView']); // Pour suivre les visites sur vos pages
}

vm.$mount('#app');
