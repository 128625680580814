<template>
  <nav class="sidebar">
    <transition name="slide">
      <ul v-if="show"
          class="sidebar__inner">
        <li class="sidebar__item p-2"
            v-bind:class="[$is_cncc ? 'bg-primary-700': 'bg-white']">
          <header-logo/>
        </li>
        <template v-for="item in nav_items">
          <li v-bind:key="item.name" class="sidebar__item">

            <text-button v-if="item.meta"
                         class="sidebar__link"
                         v-bind:href="item.meta.external_path ? item.meta.external_path : item.path"
                         v-bind:external_link="item.meta.external_path ? true : false"
                         v-bind:icon="item.meta.icon"
                         v-bind:icon_flip="item.meta.flip ? item.meta.flip : null"
                         v-bind:size="item.meta.size"
                         v-bind:label="item.meta.label"
                         width="2.5rem"
                         height="100%"
                         label_margin="1"
                         v-bind:external_same_tab="true"/>

          </li>
        </template>

      </ul>
    </transition>

    <div v-on:click="$emit('close')" class="sidebar__overlay"></div>
  </nav>
</template>

<script>
import vClickOutside from 'v-click-outside';

import navigation from '@/assets/data/data_navbar';
import textButton from '@/components/utils/text-button.vue';

import headerLogo from '@/components/header/header-logo.vue';

export default {
    components: {
        textButton,
        headerLogo
    },
    directives: {clickOutside: vClickOutside.directive},
    data() {
        return {
            show: false,
            nav_items: []
        };
    },
    created() {
        this.loadNavMenu();
        setTimeout(()=> {
            this.show = true;
        });
    },
    methods: {
        loadNavMenu() {
            navigation.nav_menu.forEach((value) => {
                this.nav_items.push(value);
            });
        },
        close() {
            this.show = false;
        }
    }
};
</script>

  <style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .sidebar {
      @apply absolute top-0 flex flex-col w-full h-screen;

      .sidebar__overlay {
        @apply fixed z-10 inset-0 bg-black bg-opacity-75;
      }

      .sidebar__inner {
          @apply z-20 w-[250px] max-w-full h-full overflow-auto bg-core-800 shadow;
      }

      .sidebar__item {
          @apply h-16 flex items-center border-b-1 border-core-700;
      }

     :deep(.text-link) {
          @apply justify-start;
      }
      .sidebar__link {
          @apply relative h-full flex bg-core-900 text-white items-center  pl-4 cursor-pointer;
          transition: background-color ease 0.5s;
          &:hover {
              @apply bg-primary-900;
          }

          &.router-link-active,
          &.router-link-exact-active {
              @apply bg-primary-800 shadow-inner;
          }

          &:after {
              content: '';
              @apply absolute flex w-2 h-full left-0 opacity-0;
              transition: opacity ease 0.3s, width ease-in-out 0.2s;
          }
          &.router-link-active:after {
              @apply opacity-100 bg-white;
          }
          &:not(.router-link-active):hover:after {
              @apply opacity-100 bg-primary-600;
          }
      }
  }
  </style>
