<template>
  <div v-if="chiffres" class="shadow bg-primary-100 sm:rounded-md p-6">
    <div class="text-center mb-4 text-primary-800 uppercase">
      CNCC FORMATION EN CHIFFRES – Année {{new Date().getFullYear() - 1}}
    </div>
    <div class="flex items-center justify-center flex-wrap space-x-4">
      <template v-for="chiffre in chiffres">
        <div v-bind:key="chiffre.id"
             class="w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 flex flex-col items-center justify-center border-2 border-dashed rounded-full bg-white shadow-lg p-4 my-2"
             v-bind:class="`${chiffre.highlight ? 'text-primary-800' : 'text-secondary-600'}`">
          <div class="text-lg sm:text-lg md:text-2xl lg:text-3xl font-bold text-center mb-2">
            {{chiffre.number}}
          </div>
          <div class="text-center text-xs sm:text-sm lg:text-base !leading-none">{{chiffre.description}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {props: {chiffres: {type: [Array, Object]}}};
</script>

<style lang="scss" scoped>

</style>