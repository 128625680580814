<template>
  <div class="h-full flex flex-col mx-auto rounded overflow-hidden p-4">

    <div class="flex flex-col w-full h-full items-center justify-center">
      <div class="max-w-screen-sm w-full bg-white shadow-md rounded py-10 px-12">

        <h1 class="flex font-bold text-core-700 text-4xl md:text-6xl mb-4">
          Erreur Serveur
        </h1>
        <h2 class="flex flex-wrap text-accent-500 text-2xl md:text-4xl mb-10 leading-none">
          Une erreur {{error_number}} est survenue...
        </h2>

        <p class="font-bold mb-6">Veuillez ré-essayer ultérieurement.</p>
        <div class="text-sm border-l-4 border-primary-900 pl-3">
          <p>Si toutefois le problème persiste, contactez-nous directement :</p>
          <ul class="list-disc list-inside pl-3 mt-1">
            <li>à l’adresse e-mail suivante : <a href="mailto:assistance@jinius.fr" class="text-primary-800 font-bold hover:underline">assistance@jinius.fr</a></li>
            <li>ou par téléphone au <span class="text-primary-800 font-bold">0825 826 116</span> (Numéro Indigo - 0.15€ TTC la minute)</li>
          </ul>
        </div>

        <a v-bind:href="goToHome"
           class="cursor-pointer font-bold text-secondary-600 hover:underline mt-8 flex">
          <font-awesome-icon icon="chevron-circle-left"
                             size="sm"
                             class="mt-1.5"/>
          <span class="text-lg mx-2">Retour à l'accueil</span>
        </a>
      </div>
    </div>

    <div class="flex mt-4 text-xs text-center self-end ">
      <code>Version&nbsp;:&nbsp;{{version}}</code>
    </div>

  </div>
</template>

<script>
export default {
    props: {
        error_number: {
            type: [String, Number],
            default: 'inconnue'
        }
    },
    computed: {
        version() {
            return process.env.VUE_APP_VERSION;
        },
        goToHome() {
            return `https://${process.env.VUE_APP_HOST_WWW}`;
        }
    }
};
</script>

<style lang="sass" scoped>
</style>
